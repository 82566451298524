import React from 'react';
import { connect } from 'react-redux';
import { createSkooter } from './actions';
import SkooterForm from './SkooterForm';

class SkooterCreate extends React.Component{
    onSubmit = formValues => {
        this.props.createSkooter(formValues);
    }

    render () {
        return (
            <div>
                <h3>Crear un Skooter</h3>
                <SkooterForm onSubmit={this.onSubmit}/>
            </div>
        );
    }
}

export default connect(null, { createSkooter })(SkooterCreate);