import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faStar, faMapMarked, faSignOutAlt, faFileExport, faHome, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import SubMenu from './SubMenu';
import { NavItem, NavLink, Nav } from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import logo from '../../assets/img/logo-red.svg'


const SideBar = props => (
    <div className={classNames('sidebar', {'is-open': props.isOpen})}>
      <div className="sidebar-header">
        <span color="info" onClick={props.toggle} style={{color: '#fff'}}>&times;</span>
        <br></br>
        <img className="mx-auto d-block" src={logo} width="200px" height="50px" alt="logo de euskooter"/> 
        <br></br>                
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
        <p>Panel Administrativo</p> 
        <NavItem>
        <NavLink tag={Link} to={'/'}>
              <FontAwesomeIcon icon={faHome} className="mr-2"/>Inicio
            </NavLink>
            </NavItem>  
          <SubMenu title="Usuarios" icon={faUserCircle} items={submenus[0]}/>               
          
          <SubMenu title="Skooters" icon={faStar} items={submenus[1]}/>

          <SubMenu title="Viajes" icon={faFileExport} items={submenus[2]}/>  

          <SubMenu title="Socios" icon={faUserFriends} items={submenus[3]}/>
          
              
          <NavItem>
            <NavLink tag={Link} to={'/salir'}>
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2"/>Salir
            </NavLink>
          </NavItem>
        </Nav>        
      </div>
    </div>
  );

  //<SubMenu title="Sitios" icon={faMapMarked} items={submenus[4]}/>

  const submenus = [
    [
      {
        title: "Agregar Usuario",
        target: "/Add_user"
      },
      {
        title: "Listar Usuarios",
        target: "/List_users",        
      },
      {
        title: "Modificar Eliminar y Viajes de Usuarios",
        target: "/Upd_Delete_users",      
      }
    ],
    [
      {
        title: "Agregar Skooter",
        target: "/Add_skooter"
      },
      {
        title: "Listar Skooters",
        target: "/List_skooters",        
      },
      {
        title: "Modificar Eliminar Skooter",
        target: "/Upd_Delete_skooters",      
      }
    ],
    [
      {
        title: "Exportar viajes por fecha",
        target: "/viajes/AllTravels",      
      }
    ],
    [
      {
        title: "Agregar Socios",
        target: "/Add_partners"
      },
      {
        title: "Listar Socios",
        target: "/List_partners",        
      },
      {
        title: "Modificar Eliminar Socios",
        target: "/Upd_Delete_partners",      
      }
    ],
    [
      {
        title: "Agregar Sitios",
        target: "/Add_places"
      },
      {
        title: "Listar Sitios",
        target: "/List_places",        
      },
      {
        title: "Modificar Eliminar Sitios",
        target: "/Upd_Delete_places",      
      }
    ]
  ]
  

export default SideBar;
