import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { fetchPartners, fetchPartnersPage, countPartners } from './actions';
import Pagination from "react-js-pagination";

import './PartnerList.css';


class PartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          activePage: 1,
          partnerCount: countPartners()
        };
      }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.props.fetchPartnersPage(pageNumber);        
      }

   async componentDidMount(){
        this.props.fetchPartners();
        this.setState({partnerCount: await countPartners()});
    }

    renderList(){
          return this.props.partners.map (partner => {
            if(partner.key){ 
            return (              
                    <tr key={partner.key}>
                    <td>{partner.id}</td>
                    <td>{partner.name}</td>
                    <td>{partner.phone}</td>                    
                    </tr>       
            )} else {
                return true;
            }        
    })}

  
    render() {
        if(this.state.partnerCount !== 0) { 
            return (
                <div>
                    <h2 className="text-center">Socios</h2>
                    <Table hover className="text-center">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nombre</th>
                                <th>Teléfono</th>                                
                            </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>
                        
                    </Table>
                    
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.partnerCount}
                            pageRangeDisplayed={8}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}         
                        />                    
                                
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto partners en una matriz
    return {
        partners: Object.values(state.partners)     
    };
};

export default connect(mapStateToProps, { fetchPartners, fetchPartnersPage })(PartnerList);

