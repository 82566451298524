import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, Label, Fade } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { dbStore } from '../../firebase/firebase';



export class CreateSkooter extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func.isRequired
      };
      constructor(props) {
        super(props);
    this.state = {
        idskooter: '',
        iotskooter:'',
        partnerID: '0',
        unlock_code: '',
        errors: null,
        fadein: false,
        message: ''
    }

    this.handleChange = this.handleChange.bind(this);   
    this.handleSubmit = this.handleSubmit.bind(this); 
  }

  componentDidMount(){      
    this.fillPartners();
  }

  fillPartners(e){
    var i;
    let partnersRef = dbStore.collection('partners').orderBy('id')
    let allPartners = partnersRef.get()
    .then(snapshot => {
        var z = document.createElement("option");
        z.setAttribute("value", '');              
        var t = document.createTextNode('Ninguno');  
        z.appendChild(t);
        document.getElementById("selectPartner").appendChild(z);
        snapshot.forEach(partner => {      
          var z = document.createElement("option");
          z.setAttribute("value", partner.id);              
          var t = document.createTextNode(partner.data().name);  
          z.appendChild(t);
          document.getElementById("selectPartner").appendChild(z);
      })
    })
  }   

  
  handleChange(event) {
  const {name, value} = event.currentTarget;
    if(name === 'skooterIdskooter') {
      this.setState({ idskooter: value.toUpperCase() })
    } 
    else if( name === 'skooterIotskooter'){
      this.setState({ iotskooter: value })
    }
    else if(name === 'selectPartner'){
      var d = document.getElementById("selectPartner").value;
      this.setState({ partnerID: d }) 
    } 
    else if(name === 'skooterUnlockCode'){
      this.setState({ unlock_code: value })    
    }    
}; 
 
    handleSubmit(event, errors, values) {
      if(errors.length===0){
      this.props.onSubmit(values)
        this.message('Agregado');       
      } else this.message('Error campos no válidos');
    }
  
  message = (message) =>{
    this.setState({
      idskooter: '',
      fadein: true,
      message: message
    })
    setTimeout (()=>{
      this.setState({
        fadein: false,
        message: ''
      })
    },3000);
  }

  

render (){     
  const { partner } = this.state;
  return(  
    <div> 
          <AvForm onSubmit={this.handleSubmit}>
        <AvGroup>
          <Label htmlFor="skooterIdskooter" className="block">
            Id Skooter:
          </Label>
          <AvField
            type="text"
            name="skooterIdskooter"
            value = {this.state.idskooter}
            placeholder="Código de 6 dígitos"
            id="skooterIdskooter"
            onChange={this.handleChange}
            errorMessage="Id no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[A-Za-z0-9]+$'},
                minLength: {value: 6},
                maxLength: {value: 6}
              }}
          />    
                
          <Label htmlFor="skooterUnlockCode" className="block">
            Código del candado:
          </Label>
          <AvField
            type="text"
            name="skooterUnlockCode"
            value = {this.state.unlock_code}
            placeholder="###"
            id="skooterUnlockCode"
            onChange={this.handleChange}
            errorMessage="Código de desbloqueo no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 3},
                maxLength: {value: 3}
              }}
          /> 

          <Label htmlFor="skooterIotskooter" className="block">
            IOT del patinete:
          </Label>
          <AvField
            type="text"
            name="skooterIotskooter"
            value = {this.state.iotskooter}
            placeholder="###############"
            id="skooterIotskooter"
            onChange={this.handleChange}
            errorMessage="IOT no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 15},
                maxLength: {value: 15}
              }}
          /> 
          <AvField 
            type="select" 
            name="selectPartner" 
            label="Socio" 
            helpMessage="Seleccione el Socio"            
            onChange={this.handleChange}/>             
          </AvGroup>          
        <Button color="danger" type="submit">Agregar Skooter</Button>
        </AvForm>
        <Fade in={ this.state.fadein } tag="h3" className="mt-3 text-center text-success">
          { this.state.message }
          </Fade>       
          </div>
    );
  };      
};    
  
  export default reduxForm({
    form: 'createSkooter'
})(CreateSkooter);

/* Candado para agregar al formulario...
<Label htmlFor="skooterUnlockCode" className="block">
            Código del candado:
          </Label>
          <AvField
            type="text"
            name="skooterUnlockCode"
            value = {this.state.unlock_code}
            placeholder="###"
            id="skooterUnlockCode"
            onChange={this.handleChange}
            errorMessage="Código de desbloqueo no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 3},
                maxLength: {value: 3}
              }}
          />  
*/      