import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { fetchSkooters, fetchSkootersPage, countSkooters } from './actions';
import Pagination from "react-js-pagination";

import './SkooterList.css';


class SkooterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          activePage: 1,
          skooterCount: countSkooters()
        };
      }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.props.fetchSkootersPage(pageNumber);        
      }

   async componentDidMount(){
        this.props.fetchSkooters();
        this.setState({skooterCount: await countSkooters()});
    }

    renderList(){
          return this.props.skooters.map (skooter => {
            if(skooter.key){ 
            return (              
                    <tr key={skooter.key}>
                    <td>{skooter.id}</td>
                    <td>{skooter.bateria}</td>
                    <td>{skooter.battery_level}</td>
                    <td>{skooter.cobertura}</td>
                    <td>{skooter.ultima_latitud}</td>
                    <td>{skooter.ultima_longitud}</td>
                    </tr>       
            )} else {
                return true;
            }        
    })}

  
    render() {
        if(this.state.skooterCount !== 0) { 
            return (
                <div>
                    <h2 className="text-center">Skooters</h2>
                    <Table hover className="text-center">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Bateria</th>
                                <th>Nivel de Bateria</th>
                                <th>Cobertura</th>
                                <th>Ultima Latitud</th>
                                <th>Ultima Longitud</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>
                        
                    </Table>
                    
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.skooterCount}
                            pageRangeDisplayed={8}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}         
                        />                    
                                
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto skooters en una matriz
    return {
        skooters: Object.values(state.skooters)     
    };
};

export default connect(mapStateToProps, { fetchSkooters, fetchSkootersPage })(SkooterList);

