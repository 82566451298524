import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalDelete from '../Modal';
//import history from '../../history';
import { fetchUser, deleteUser } from './actions';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';


class UserDelete extends React.Component{
    componentDidMount(){
        this.props.fetchUser(this.props.match.params.id);
    }
    renderActions(){
        const id = this.props.match.params.id;        
        return (
            <React.Fragment>
                <Button onClick={() => this.props.deleteUser(id)} className="primary">Eliminar</Button>
                <Link to="/Upd_Delete_users" className="secondary">Cancelar</Link>
            </React.Fragment>
        );
    }

renderContent(){
        return `Está seguro que quiere borrar el usuario con el nombre: ${this.props.user.name} y el email: ${this.props.user.email}`            
} 

render(){
    if (!this.props.user){      
    return (<Redirect to={{
      pathname: '/Upd_Delete_users'
    }}/>)    
}
    return (                   
        <ModalDelete
            title="Eliminar Usuario"
            content={this.renderContent()}
            actions={this.renderActions()}            
        />          
    );
}
}

const mapStateToProps = (state, ownProps) =>{
    return { user: state.users[ownProps.match.params.id] }
};

export default connect(mapStateToProps, { fetchUser, deleteUser })(UserDelete);
