import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalDelete from '../Modal';
import history from '../../history';
import { fetchSkooter, deleteSkooter } from './actions';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';


class SkooterDelete extends React.Component{
    componentDidMount(){
        this.props.fetchSkooter(this.props.match.params.id);
    }
    renderActions(){
        const id = this.props.match.params.id;
        return (
            <React.Fragment>
                <Button onClick={() => this.props.deleteSkooter(id)} className="primary">Eliminar</Button>
                <Link to="/Upd_Delete_skooters" className="secondary">Cancelar</Link>
            </React.Fragment>
        );
    }

renderContent(){
    if(this.props.skooter){
        return `Está seguro que quiere borrar el skooter con el id: ${this.props.skooter.id}`            
} else {
    history.push('/Upd_Delete_skooters');
}
}

render(){
    if (!this.props.skooter){      
    return (<Redirect to={{
      pathname: '/Upd_Delete_skooters'
    }}/>)    
}
    return (                   
        <ModalDelete
            title="Eliminar Skooter"
            content={this.renderContent()}
            actions={this.renderActions()}
            onDismiss={() => history.push('/')}
        />          
    );
}
}

const mapStateToProps = (state, ownProps) =>{
    return { skooter: state.skooters[ownProps.match.params.id] }
};

export default connect(mapStateToProps, { fetchSkooter, deleteSkooter })(SkooterDelete);
