import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, FormGroup, Label, Fade } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';

export class CreateUser extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func.isRequired
      };
      constructor(props) {
        super(props);
    this.state = {
        name: '',
        email: '',
        balance: '',
        created_at: '', 
        upv_free: false,       
        errors: null,
        fadein: false,
        message: ''
    }

    this.handleChange = this.handleChange.bind(this);   
    this.handleSubmit = this.handleSubmit.bind(this); 
  }
  

  handleChange(event) {
    const {name, value} = event.currentTarget;
    
    if(name === 'userName') {
        this.setState({ name: value })
    }
    else if(name === 'userEmail'){
        this.setState({ email: value })    
    }
    else if(name === 'userBalance'){
        this.setState({ balance: value })
    }
    else if(name === 'created_at'){
        this.setState({ created_at: value })
    }    
    }; 
    

 
    handleSubmit(event, errors, values) {
      if(errors.length===0){
      this.props.onSubmit(values)
        this.message('Agregado');       
      } else this.message('Error campos no válidos');
    }
  
  message = (message) =>{
    this.setState({
      name: '',
      email: '',
      balance: '',
      created_at: '',
      fadein: true,
      message: message
    })
    setTimeout (()=>{
      this.setState({
        fadein: false,
        message: ''
      })
    },3000);
  }

render (){   
  return(  
    <div> 
          <AvForm onSubmit={this.handleSubmit}>
        <AvGroup>
          <Label htmlFor="userName" className="block">
            Nombre:
          </Label>
          <AvField
            type="text"
            name="userName"
            value = {this.state.name}
            placeholder="Nombre de usuario"
            id="userName"
            onChange={this.handleChange}
            errorMessage="Nombre no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9_-]*)+$'},
                minLength: {value: 2},
                maxLength: {value: 20}
              }}
          />
          </AvGroup>   
          <FormGroup>
          <Label htmlFor="userEmail" className="block">
            Correo:
          </Label>
          <AvField
            type="email"
            name="userEmail"
            value = {this.state.email}
            placeholder="Email del Usuario"
            id="userEmail"
            onChange={this.handleChange}       
            errorMessage="El correo del usuario es obligatorio"
            validate={{
                required: {value: true},
                minLength: {value: 4},
                maxLength: {value: 40}
              }}                  
          />   
          </FormGroup>
          <FormGroup>
          <Label htmlFor="userBalance" className="block">
            Balance:
          </Label>
          <AvField
            type="number"
            name="userBalance"
            value = {this.state.balance}
            placeholder="1"
            id="userBalance"
            onChange={this.handleChange} 
          />
          </FormGroup>   
          
          <FormGroup>
          <Label htmlFor="created_at" className="block">
            Fecha de creado:
          </Label>
          <AvField
            type="date"
            name="created_at"
            value = {this.state.balance}
            placeholder="1"
            id="created_at"
            onChange={this.handleChange} 
          />
          </FormGroup>    
          <AvGroup check>
        <Label check>
          <AvInput type="checkbox" name="userUpv" value={this.state.upv_free} /> Suscripción Gratuita
        </Label>
      </AvGroup>       
        <Button color="danger" type="submit">Agregar Usuario</Button>
        </AvForm>
        <Fade in={ this.state.fadein } tag="h3" className="mt-3 text-center text-success">
          { this.state.message }
          </Fade>       
          </div>
    );
  };      
};    
  
  export default reduxForm({
    form: 'createUser'
})(CreateUser);

