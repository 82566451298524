import _ from 'lodash';
import {
    FETCH_PARTNER,
    FETCH_PARTNERS,
    CREATE_PARTNER,
    DELETE_PARTNER
} from './action-types';

export function partnersReducer (state = {}, action) {
    switch (action.type) {
        case FETCH_PARTNERS:
            return { state, ..._.mapKeys(action.payload, 'id') };
        case FETCH_PARTNER:
            return { ...state, [action.payload.id]: action.payload };     
        case CREATE_PARTNER:
            return { ...state, [action.payload.id]: action.payload }; 
        case DELETE_PARTNER:
            return _.omit(state, action.payload);
        default:
            return state;
    }
}