import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, FormGroup, Label, Fade, Te } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';

export class CreatePartner extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func.isRequired
      };
      constructor(props) {
        super(props);
    this.state = {
        idpartner: '',
        name: '',
        phone: '',
        status: '',       
        errors: null,
        fadein: false,
        message: ''
    }

    this.handleChange = this.handleChange.bind(this);   
    this.handleSubmit = this.handleSubmit.bind(this); 
  }
  

  handleChange(event) {
    const {name, value} = event.currentTarget;
    if(name === 'idpartner') {
        this.setState({ idpartner: value })
    }
    else if(name === 'partnerName') {
        this.setState({ name: value })
    }
    else if(name === 'telephone'){
        this.setState({ phone: value })    
    }     
    }; 

    handleSubmit(event, errors, values) {
      if(errors.length===0){
      this.props.onSubmit(values)
        this.message('Agregado');       
      } else this.message('Error campos no válidos');
    }
  
  message = (message) =>{
    this.setState({
        idpartner: '',
        name: '',        
        phone: '',
        status: '',   
        fadein: true,
        message: message
    })
    setTimeout (()=>{
      this.setState({
        fadein: false,
        message: ''
      })
    },3000);
  }

render (){   
  return(  
    <div> 
          <AvForm onSubmit={this.handleSubmit}>
          <AvGroup>
          <Label htmlFor="idpartner" className="block">
            Id Socio:
          </Label>
          <AvField
            type="text"
            name="idpartner"
            value = {this.state.idpartner}
            placeholder="Id del Socio"
            id="idpartner"
            onChange={this.handleChange}
            errorMessage="Id no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 1},
                maxLength: {value: 3}
              }}
          />         
    
          </AvGroup>

        <FormGroup>
          <Label htmlFor="partnerName" className="block">
            Nombre:
          </Label>
          <AvInput
            type="text"
            name="partnerName"
            id="partnerName"
            onChange={this.handleChange}
            errorMessage="Nombre no válido"
            validate={{
                required: {value: true},                
                minLength: {value: 2},
                maxLength: {value: 100}
              }}
          />
          </FormGroup>   
          
          <AvGroup>
          <Label htmlFor="telephone" className="block">
            Teléfono Socio:
          </Label>
          <AvField
            type="text"
            name="telephone"
            value = {this.state.phone}
            placeholder="Teléfono del Socio"
            id="telephone"
            onChange={this.handleChange}
            errorMessage="Teléfono no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 9},
                maxLength: {value: 9}
              }}
          />         
    
          </AvGroup>

        <Button color="danger" type="submit">Agregar Socio</Button>
        </AvForm>
        <Fade in={ this.state.fadein } tag="h3" className="mt-3 text-center text-success">
          { this.state.message }
          </Fade>       
          </div>
    );
  };      
};    
  
  export default reduxForm({
    form: 'createPartner'
})(CreatePartner);

