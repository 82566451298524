import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Row, Col  } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { fetchUsers, fetchUsersPage, exportarJson, countUsers } from './actions';
import Pagination from "react-js-pagination";
import './UserList.css';

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          activePage: 1,
          items: 0,
          userCount: countUsers()
        };
      }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.props.fetchUsersPage(pageNumber);        
      }
      
    handleSubmit() {              
            exportarJson();
        };
         
    async componentDidMount(){
        this.props.fetchUsers();
        this.setState({userCount: await countUsers()});             
    }

    renderList(){
        const usersArray = this.props.users
            return (
              usersArray.map ((user) => {
            if(user.key){                 
            return (                                
                <tr key={user.key}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.id}</td>
                <td>{user.balance}</td> 
                <td>{user.stripeCustomerId}</td>                   
                </tr>
                )
            }   else {
                return true;
            }        
          }) 
           )
    }

  
    render() {
        if(this.state.userCount !== 0) {            
        return (
            <div>
                <div>
                <Row>
                    <Col sm="12" md={{ size: 7 }}>
                        <h2 className="text-center">Usuarios</h2>
                    </Col>                    
                <Col sm="12" md={{ size: 5 }}>
                    <div className="buscar">
                <AvForm onSubmit={this.handleSubmit}>
                    <Button color="danger" type="submit">Exportar Usuario</Button>
                </AvForm> 
                </div>
                </Col>  
                </Row> 
                </div>                 
                <Table hover className="text-center">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>ID</th>
                            <th>Balance</th>
                            <th>Customer ID</th>
                        </tr>
                    </thead>                
                <tbody>{this.renderList()}</tbody> 
                </Table>     
                <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.userCount-10}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={this.handlePageChange.bind(this)}         
                    />        
            </div>
        );
        } 
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto users en una matriz
    return {
        users: Object.values(state.users)            
    };
};

export default connect(mapStateToProps, { fetchUsers, fetchUsersPage, exportarJson })(UserList);

