import React from 'react';
import { connect } from 'react-redux';
//import { fetchTravel } from './actions';
import PropTypes from 'prop-types';
import { dbStorage } from '../../firebase/firebase';


class TravelPicture extends React.Component{
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
    };
    constructor(props) {
      super(props);
      this.state = {
        urlImg:''
    }        
};

    componentDidMount() {    
       dbStorage.ref(`parkings/${this.props.match.params.id}`).getDownloadURL().then((url) => {
        //console.log("iiiii " + url)   
        this.setState({urlImg:url})
      }).catch(function(error) {
        return false;
      });      
    };  


    render() {            
         
    return (                 
      <div> 
          <img src={this.state.urlImg} alt="No tiene foto"></img>
      </div>   
    );}    
};

const mapStateToProps = (state, ownProps) => {
    return { travel: state.travels[ownProps.match.params.id]};
};

export default connect(mapStateToProps)(TravelPicture);