import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Row, Col  } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { fetchUsers, fetchUsersPage, fetchUsersSearch, countUsers } from './actions';
import Pagination from "react-js-pagination";
import './UserList.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';



class UserEditDelete extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
          activePage: 1,
          email: '',
          userCount: countUsers()
        };
      //  this.handleSubmit = this.handleSubmit.bind(this); 
        this.handleChange = this.handleChange.bind(this);
      } 

     handleChange(event) {
        const {value} = event.currentTarget; 
            this.setState({ email: value })            
    }


    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.props.fetchUsersPage(pageNumber);        
    }

  /*  handleSubmit(event, errors, values) {               
        if(errors.length===0){ 
          this.props.fetchUsersSearch(values.userEmail)   
          this.setState({activePage: 1});                    
        } else console.log('no entra al metodo')
      }; */   
    
    async componentDidMount(){
        this.props.fetchUsers();
        this.setState({userCount: await countUsers()});             
    }

    renderList(){
          return this.props.users.map (user => {              
              if(user.key){             
            return (              
                    <tr key={user.key}>
                    <td>{user.name}</td> 
                    <td>{user.email}</td>                                                            
                    <td><Link to={`/users/edit/${user.key}`} className="button primary"><Button><FontAwesomeIcon className="fas fa-fw" width="25" height="25" icon={faEdit}/></Button></Link></td>
                    <td><Link to={`/users/delete/${user.key}`} className="button negative"><Button color="danger"><FontAwesomeIcon className="fas fa-fw" width="25" height="25" icon={faTimes}/></Button></Link></td>
                    <td><Link to={`/viajes/list/${user.key}`} className="button info"><Button color="info"><FontAwesomeIcon className="fas fa-fw" width="25" height="25" icon={faCalendarCheck}/></Button></Link></td>
                    </tr> 
                )} else {
                        return true;
                    }                       
            }
        )  
        
    };

  
    render() {
        if(this.state.userCount !== 0) {
            return (
            
                <div>
                    <div>
                    <Row>
                        <Col sm="12" md={{ size: 7 }}>
                            <h2 className="text-center">Usuarios</h2>
                        </Col>                    
                    <Col sm="12" md={{ size: 5 }}>
                        <div className="buscar">
                    <AvForm onSubmit={this.handleSubmit}>
                        <AvGroup>
                        <AvField
                            type="text"
                            name="userEmail"
                            placeholder="Email del Usuario"
                            id="userEmail"
                            onChange={this.handleChange}
                            value = {this.state.email}
                            errorMessage="Debe introducir un parámetro de búsqueda"
                            validate={{
                                required: {value: true}                            
                            }}                  
                        />   
                        </AvGroup>
                        <Link to={`/users/found/${this.state.email}`} className="button info"><Button color="danger">Buscar Usuario</Button></Link>
                    </AvForm> 
                    </div>
                    </Col>  
                    </Row> 
                    </div>             
                    <Table hover className="text-center">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo</th>                            
                                <th>Editar</th>
                                <th>Eliminar</th>
                                <th>Mostrar Viajes</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>                    
                    </Table>   
                    <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.userCount-10}
                            pageRangeDisplayed={8}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}         
                        />                             
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto users en una matriz
    return {
        users: Object.values(state.users)     
    };
};

export default connect(mapStateToProps, { fetchUsers, fetchUsersPage, fetchUsersSearch })(UserEditDelete);

