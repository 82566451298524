import React from 'react';
import { Button, Row, Col, FormGroup, Label, Fade  } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { reduxForm } from 'redux-form';
import { exportarJson } from './actions';
import './AllTravels.css';

class AllTravels extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired
        };
    constructor(props) {
        super(props);
        this.state = {
          start: null,
          end: null,
          fadein: false,
          message: ''
        };
        this.handleChange = this.handleChange.bind(this);   
        this.handleSubmit = this.handleSubmit.bind(this); 
      }   
      
        
    handleSubmit(event, errors, values) { 
        var start_tmp = values.start_at
        var end_tmp = values.end_at          
        var start_date = parseInt(start_tmp.replace(/-|:|\s/g , ""))
        var end_date = parseInt(end_tmp.replace(/-|:|\s/g , ""))                        
        if(errors.length===0 && start_date < end_date){
            this.message('Generando Excel....');   
            exportarJson(values);
            } else this.message('Error fechas no válidas');
        };
    
        message = (message) =>{
            this.setState({
              start: '',
              end: '',
              fadein: true,
              message: message
            })
            setTimeout (()=>{
              this.setState({
                fadein: false,
                message: ''
              })
            },20000);
          }    

    handleChange(event) {
        const {name, value} = event.currentTarget;
            
        if(name === 'start_at') {
            this.setState({ start: value })
        }
        else if(name === 'end_at'){
            this.setState({ end: value })    
        }        
        }; 
         
    render() {
        return (            
            <div>                
                <AvForm onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm="12" md={{ size: 4 }}>
                        <FormGroup>
                            <Label htmlFor="start_at" className="block">
                                Fecha Inicial:
                            </Label>
                            <AvField
                                type="date"
                                name="start_at"
                                value = {this.state.start}
                                placeholder="1"
                                id="start_at"
                                onChange={this.handleChange} 
                                errorMessage="Fecha no válida"
                                required
                            />
                        </FormGroup>  
                        </Col>
                        <Col sm="12" md={{ size: 4 }}>
                        <FormGroup>
                            <Label htmlFor="end_at" className="block">
                                Fecha Final:
                            </Label>
                            <AvField
                                type="date"
                                name="end_at"
                                value = {this.state.end}
                                placeholder="1"
                                id="end_at"
                                onChange={this.handleChange} 
                                errorMessage="Fecha no válida"
                                required
                            />
                        </FormGroup>   
                    </Col>                    
                <Col sm="12" md={{ size: 4 }}>
                    <div className="buscar">
                
                    <Button color="danger" type="submit">Exportar Viajes</Button>
                    </div>
                    </Col>  
                </Row> 
                </AvForm> 
                <Fade in={ this.state.fadein } tag="h3" className="mt-3 text-center text-success">
          { this.state.message }
          </Fade> 
                </div> 
        );
    }
}

/*const mapStateToProps = (state) => {
    // convierte el objeto users en una matriz
    return {
        users: Object.values(state.users)            
    };
};*/

export default connect(null, { exportarJson })(AllTravels);

