export const firebaseConfig = {
  apiKey: "AIzaSyBep5xsAtCRREv1Q0XyaLT50vaDhC6b31Y",
    authDomain: "euskooters.firebaseapp.com",
    databaseURL: "https://euskooters.firebaseio.com",
    projectId: "euskooters",
    storageBucket: "euskooters.appspot.com",
    messagingSenderId: "406968921183",
    appId: "1:406968921183:web:dcde4c1e7c24a0d6663fea",
    measurementId: "G-811M2QCBZM"  
};
