import React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'reactstrap';
import { fetchUsersSearch } from './actions';
//import Pagination from "react-js-pagination";
import './UserList.css';
import { Link } from 'react-router-dom';



class UserEditDelete extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          buscado: ''
        };       
      } 

    componentDidMount(){
        this.props.fetchUsersSearch(this.props.match.params.email);
    }

    renderList(){
          return this.props.users.map (user => {              
              if(user.key){             
            return (              
                    <tr key={user.key}>
                    <td>{user.name}</td> 
                    <td>{user.email}</td>
                    <td>{user.id}</td>
                    <td>{user.stripeCustomerId}</td>                                                             
                    <td><Link to={`/users/edit/${user.key}`} className="button primary"><Button>Editar</Button></Link></td>
                    <td><Link to={`/users/delete/${user.key}`} className="button negative"><Button color="danger">Eliminar</Button></Link></td>
                    <td><Link to={`/viajes/list/${user.key}`} className="button info"><Button color="info">Mostrar Viajes</Button></Link></td>
                    </tr> 
                )} else {
                        return true;
                    }                       
            }
        )  
        
    };
  
    render() {
        return (
          
            <div>                           
                <Table hover className="text-center">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>ID</th>
                            <th>Customer ID</th>                            
                            <th>Editar</th>
                            <th>Eliminar</th>
                            <th>Mostrar Viajes</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderList()}</tbody>                    
                </Table>                                            
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto users en una matriz
    return {
        users: Object.values(state.users)     
    };
};

export default connect(mapStateToProps, { fetchUsersSearch })(UserEditDelete);

