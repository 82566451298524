import React, { useContext } from "react";
import TasksPage from '../pages/tasks';
import SignInPage from '../pages/sign-in';
import { UserContext } from "../providers/UserProvider";
function Application() {
  const user = useContext(UserContext);
  return (
        user ?
        <TasksPage />
      :
        <SignInPage />          
  );
}

export default Application;
