import _ from 'lodash';
import {
    FETCH_SKOOTER,
    FETCH_SKOOTERS,
    CREATE_SKOOTER,
    EDIT_SKOOTER,
    DELETE_SKOOTER
} from './action-types';

export function skootersReducer (state = {}, action) {
    switch (action.type) {
        case FETCH_SKOOTERS:
            return { state, ..._.mapKeys(action.payload, 'id') };
        case FETCH_SKOOTER:
            return { ...state, [action.payload.id]: action.payload };     
        case CREATE_SKOOTER:
            return { ...state, [action.payload.id]: action.payload }; 
        case EDIT_SKOOTER:
            return { ...state, [action.payload.id]: action.payload }; 
        case DELETE_SKOOTER:
            return _.omit(state, action.payload);
        default:
            return state;
    }
}