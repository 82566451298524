import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import configureStore from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';


const store = configureStore();
const rootElement = document.getElementById('root');

  ReactDOM.render(
    <Provider store={store}>
    <App />
    </Provider>,
    rootElement
  );

  registerServiceWorker.unregister(); 
