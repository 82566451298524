import React from 'react';
import mapboxgl from 'mapbox-gl';
import mapIcon from '../../assets/img/marker-full.svg';
import inactive from '../../assets/img/marker-inactive.svg';
import low from '../../assets/img/marker-low.svg';
import medium from '../../assets/img/marker-medium.svg';
import unlocked from '../../assets/img/marker-euskooters-green.svg';
import 'mapbox-gl/dist/mapbox-gl.css';
import { connect } from 'react-redux';
import { fetchSkooter, editSkooter, commandSkooterCall } from './actions';
import PropTypes from 'prop-types';
import { Label, Col, Row } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamationCircle, faLock, faLockOpen, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { dbStore } from '../../firebase/firebase';
import { destroy } from 'redux-form';

class SkooterEdit extends React.Component{
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
    };
    constructor(props) {
      super(props);
      this.state = {
        lng: -3.0012,
        lat: 43.3305,
        zoom: 5,
        id: '',
        idOld: '',
        iotskooter:'',
        unlock_code: '',
        partnerID: '',
        panicMode: null,
        errors: null,
        fadein: false,
        skooter_new: null,
        intervalMap: null,
    }      
  this.handleSubmit = this.handleSubmit.bind(this); 
  this.changeHandler = this.changeHandler.bind(this);  
  this.loadMap = this.loadMap.bind(this);
  this.getSkooter = this.getSkooter.bind(this);
  this.actPanic = this.actPanic.bind(this);
  this.desactPanic = this.desactPanic.bind(this);
}

    componentDidMount() { 
        this.props.fetchSkooter(this.props.match.params.id);
        this.fillPartners();       
        this.loadMap();                            
    }

    componentWillUnmount(){
      clearInterval(this.state.intervalMap);
    }

    
    fillPartners(e){
      try{
      var i;
      var options = document.querySelectorAll('#selectPartner option');
      options.forEach(o => o.remove());
      let partnersRef = dbStore.collection('partners').orderBy('id')
      let allPartners = partnersRef.get()
      .then(snapshot => {
          var z = document.createElement("option");
          z.setAttribute("value", '');              
          var t = document.createTextNode('Ninguno');  
          z.appendChild(t);
          document.getElementById("selectPartner").appendChild(z);
          snapshot.forEach(partner => {      
            var z = document.createElement("option");
            z.setAttribute("value", partner.id);
            if(partner.id === this.props.skooter.partner_id){
              z.setAttribute('selected', true);
            }              
            var t = document.createTextNode(partner.data().name);  
            z.appendChild(t);
            document.getElementById("selectPartner").appendChild(z);
        })
      })} catch {
        console.log("error de carga  ")
      }
    }   

      
    loadMap(){
    try{
      mapboxgl.accessToken = 'pk.eyJ1IjoiZXVza29vdGVycyIsImEiOiJjazZjNG9ydTYweGh6M2ttZ3M1am12cWY3In0.yYVJ1EpyEFcpNvFHCPRRCg'         

      const map1 = new mapboxgl.Map({
          container: this.mapContainer,
          style: 'mapbox://styles/euskooters/ckc38z9m00mr31il6c91y6auq',
          center: [this.state.lng, this.state.lat],
          zoom: this.state.zoom
          }); 
          
          const interval = setInterval(async () => {            
            await this.createMarkers(map1);          
            }, 2000);

            this.setState ({ intervalMap: interval });
           }
          catch{
            console.log('error controlado')
          }  

          
    /*var options = document.querySelectorAll('#selectPartner option');
    options.forEach(o => o.remove()); 
    this.fillPartners(); */    
  }   

  
 getSkooter = async(id)  => {
  await dbStore.collection('skooters').doc(id).get().then((doc) => {
    // check if the document exists
    if (doc.exists) {
      const response = doc.data();
      this.setState({skooter_new: response})
      return true;      
    } else {
      throw new Error('No Such Document');
    }
})    
}
  removeMarkers() {
    var elements = document.getElementsByClassName('marker');
    while (elements.length > 0) elements[0].remove();
  }

  async createMarkers(map) {     
    var skooter1 = this.props.skooter
    
    var skooter_get = this.getSkooter(skooter1.id)
    
    try{
    if (skooter_get){
      this.removeMarkers();      
      var skooter = this.state.skooter_new;
      var el = document.createElement('div');
                  el.className = 'marker';
                  try {
                    if(typeof skooter.status.code === 'undefined'){
                      console.log("No tiene code el skooter") 
                   } else {
    
                    if(skooter.battery_level >= 85){
                        el.style.backgroundImage = 'url("'+mapIcon+'")'  
                    } else if(skooter.battery_level <= 30 && skooter.battery_level !== ''){
                        el.style.backgroundImage = 'url("'+low+'")'  
                    } else if(30 < skooter.battery_level < 85){
                        el.style.backgroundImage = 'url("'+medium+'")' 
                    }
                    if(skooter.status.code === "inactive"){
                        el.style.backgroundImage = 'url("'+inactive+'")' 
                    }
                    if(skooter.status.code === "unlocked"){
                        el.style.backgroundImage = 'url("'+unlocked+'")' 
                    }
                   }
                } catch {
                    console.log("Skooter sin datos: " + skooter.id)
                }
                  el.style.width = '30px';
                  el.style.height = '30px'; 
                  el.addEventListener('click', function () {
                     map.flyTo({
              center: ([skooter.ultima_longitud, skooter.ultima_latitud]),
              zoom: 15
          })          
          var popup = new mapboxgl.Popup({ closeOnClick: false })
          .setLngLat([skooter.ultima_longitud, skooter.ultima_latitud])
          .setHTML(  
            `<h4>${skooter.id}</h4>
            <label for="file">Batería:&nbsp;${skooter.battery_level}%&nbsp;</label>
            <progress id="file" value="${skooter.battery_level}" max="100">  </progress>  
            <label for="file">Velocidad Instantánea:&nbsp;${skooter.speed}&nbsp;</label>
            </br>
            <label for="file">Odómetro:&nbsp;${skooter.odometer}&nbsp;</label>
            </br>
            <label for="file">Cobertura:&nbsp;${skooter.networkSignal}&nbsp;</label>
            </br>
            <label for="file">Rango restante:&nbsp;${skooter.remainingRange}&nbsp;</label>
            </br>
            <label for="file">Tiempo total:&nbsp;${skooter.totalRidingSecs}&nbsp;</label>   
            </br>         
                           
            `          
          )
          .addTo(map);       
      });
  
          if(skooter.id){                   
                  new mapboxgl.Marker(el)
                  .setLngLat([skooter.ultima_longitud, skooter.ultima_latitud])
                  .addTo(map); 
                }              
      }
    } 
      catch { console.log ("first Time")}
    }
    

    changeHandler = ( event ) => {
      const {name, value} = event.currentTarget;
      if(name === 'skooterIdskooter') {
        this.setState({ id: value.toUpperCase() })
      }
      else if( name === 'skooterIotskooter'){
        this.setState({ iotskooter: value })
      }
      else if(name === 'selectPartner'){
        var d = document.getElementById("selectPartner").value;
        this.setState({ partnerID: d })  
      }      
      else if(name === 'skooterUnlockCode'){
        this.setState({ unlock_code: value })    
      }     
    }

   
    handleSubmit(event, errors, values) {
      if(errors.length===0){
      alert('Patinete modificado')
      this.props.editSkooter(this.props.match.params.id, values)        
      } else alert('Error campos no válidos');
    }

    message = (message) =>{
      this.setState({      
        fadein: true,
        message: message
      })
      setTimeout (()=>{
        this.setState({
          fadein: false,
          message: ''
        })
      },3000);
    }
    
    async panicFunction(){
      await commandSkooterCall(this.props.skooter.iot, "PA1", this.props.skooter.id)     
    }

    actPanic = () => {
      alert("Modo pánico activado " + this.props.skooter.id)    
      const intervalID = setInterval(async () => {            
        await this.panicFunction();          
        }, 10000);
      this.setState({panicMode: intervalID})      
  };

  desactPanic = () => {
    clearInterval(this.state.panicMode); 
    alert("Modo pánico desactivado " + this.props.skooter.id)   
  };

  lockSkooter = () => {
    commandSkooterCall(this.props.skooter.iot, "STO", this.props.skooter.id)
};

unlockSkooter = () => {
  commandSkooterCall(this.props.skooter.iot, "STA", this.props.skooter.id)
};

actSkooter = () => {
  commandSkooterCall(this.props.skooter.iot, "BEL", this.props.skooter.id)
}

    render() {          
    if (!this.props.skooter){                 
        return (<Redirect to={{
          pathname: '/Upd_Delete_skooters'
        }}/>)    
    }
    else {        
        const defaultValues = {
            skooterIdskooter: this.props.skooter.id,
            skooterIotskooter: this.props.skooter.iot, 
            skooterUnlockCode:  this.props.skooter.unlock_code, 
            selectPartner: this.props.skooter.partner_id        
        }
    return (
        <div>
            <h3>Editar un Skooter</h3>
            <div> 
          <AvForm onSubmit={this.handleSubmit} model={defaultValues}>
        <AvGroup>
        <Row> 
        <Col sm={{ size: '6', offset: 0 }}> 
          <Label htmlFor="skooterIdskooter" className="block">
            Id Skooter:
          </Label>
          <AvField
            type="text"
            name="skooterIdskooter"
            placeholder="Código de 6 dígitos"
            id="skooterIdskooter"
            errorMessage="Id no válido"
            value={this.state.id}
            validate={{
                required: {value: true},
                pattern: {value: '^[A-Za-z0-9]+$'},
                minLength: {value: 6},
                maxLength: {value: 6}
              }}
            onChange={this.changeHandler}            
          />
          <AvField 
            type="select" 
            name="selectPartner" 
            label="Socio" 
            helpMessage="Seleccione el Socio"  
            value={this.state.partnerID}          
            onChange={this.changeHandler}/> 
          </Col>
          <Col sm={{ size: '6', offset: 0 }}> 
          <Label htmlFor="skooterUnlockCode" className="block">
            Código del candado:
          </Label>
          <AvField
            type="text"
            name="skooterUnlockCode"
            value = {this.state.unlock_code}
            placeholder="###"
            id="skooterUnlockCode"
            onChange={this.changeHandler}
            errorMessage="Código de desbloqueo no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 3},
                maxLength: {value: 3}
              }}
          />  
          <Label htmlFor="skooterIotskooter" className="block">
            IOT del patinete:
          </Label>
          <AvField
            type="text"
            name="skooterIotskooter"
            value = {this.state.iotskooter}
            placeholder="###############"
            id="skooterIotskooter"
            onChange={this.handleChange}
            errorMessage="IOT no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 15},
                maxLength: {value: 15}
              }}
          /> 
          </Col>
          </Row>   
          </AvGroup> 
          <Row>
          <Col sm={{ size: '4', offset: 0 }}> 
          <div className='group_buttons'>        
            <button type="submit" className="button_transparent"><FontAwesomeIcon className="fas fa-fw" icon={faEdit} width="20" height="20" color="#DC373D"/>&nbsp;Modificar Skooter</button>
            <br></br>
            <button type="button" className="button_transparent" onClick={this.actPanic}><FontAwesomeIcon className="fas fa-fw" icon={faExclamationCircle} width="20" height="20" color="#FEC107"/>&nbsp;Activar Modo Pánico</button>
            <br></br>
            <button type="button" className="button_transparent" onClick={this.desactPanic}><FontAwesomeIcon className="fas fa-fw" icon={faExclamationCircle} width="20" height="20" color="#29A744"/>&nbsp;Desactivar Modo Pánico</button>
            <br></br>
            <button type="button" className="button_transparent" onClick={this.lockSkooter}><FontAwesomeIcon className="fas fa-fw" icon={faLock} width="20" height="20" color="#6D747C"/>&nbsp;Bloquear Skooter</button>
            <br></br>
            <button type="button" className="button_transparent" onClick={this.unlockSkooter}><FontAwesomeIcon className="fas fa-fw" icon={faLockOpen} width="20" height="20" color="#14A2B6"/>&nbsp;Desbloquear Skooter</button>
            <br></br>
            <button type="button" className="button_transparent" onClick={this.actSkooter}><FontAwesomeIcon className="fas fa-fw" icon={faSyncAlt} width="20" height="20" color="#0068D9"/>&nbsp;Actualizar Skooter</button>
            </div>
          </Col>      
          
          <Col sm={{ size: '8', offset: 0 }}> 
        <div className='map_skooter'>
                <div ref={ell => this.mapContainer = ell} className='mapContainer' />                  
                </div> 
                </Col>
                </Row> 
                </AvForm>                        
          </div>          
        </div>    
    );}
    }
}

const mapStateToProps = (state, ownProps) => {
    return { skooter: state.skooters[ownProps.match.params.id]};
};

export default connect(mapStateToProps, { fetchSkooter, editSkooter })(SkooterEdit);
/* Candado
<Label htmlFor="skooterUnlockCode" className="block">
            Código del candado:
          </Label>
          <AvField
            type="text"
            name="skooterUnlockCode"
            value = {this.state.unlock_code}
            placeholder="###"
            id="skooterUnlockCode"
            onChange={this.changeHandler}
            errorMessage="Código de desbloqueo no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 3},
                maxLength: {value: 3}
              }}
          />       
*/  