import React from 'react';
import { signOut } from './actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class Salir extends React.Component {
    componentDidMount(){
        this.props.signOut();
        console.log('pasa por component')
    }

    render(){
        return (<Redirect to={{
            pathname: '/sign-in'            
          }}/>) 
    }
}

const mapStateToProps = (state) => {
    return {
        auth: Object.values(state.auth)     
    };
};

export default connect(mapStateToProps, { signOut })(Salir);

