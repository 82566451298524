import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Row, Col  } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { fetchSkooters, fetchSkootersPage, fetchSkootersSearch, countSkooters } from './actions';
import Pagination from "react-js-pagination";
import './SkooterList.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faQrcode, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

class SkooterEditDelete extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
          activePage: 1,
          id: null,
          skooterCount: countSkooters()
        };

        this.handleSubmit = this.handleSubmit.bind(this); 
        this.handleChange = this.handleChange.bind(this);       
      }

      handleChange(event) {
        const {value} = event.currentTarget;
        this.setState({ id: value.toUpperCase() })        
    } 
 

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber});
        this.props.fetchSkootersPage(pageNumber);        
      }

    handleSubmit(event, errors, values) {
        //console.log('entra al submit')
        if(errors.length===0){   
            console.log('entra al metodo de buscar')     
          this.props.fetchSkootersSearch(values.skooterId)   
          this.setState({activePage: 1});               
                              
        } else console.log('no entra al metodo')
      };

   async componentDidMount(){
        this.props.fetchSkooters();
        this.setState({skooterCount: await countSkooters()});
    }

    
    renderList(){
          return this.props.skooters.map (skooter => {
            if(skooter.key){ 
            return (
                    <tr key={skooter.key}>
                    <td>{skooter.id}</td>                                                               
                    <td><Link to={`/skooters/edit/${skooter.id}`} className="button primary"><Button><FontAwesomeIcon className="fas fa-fw" icon={faEdit} width="25" height="25"/></Button></Link></td>
                    <td><Link to={`/skooters/delete/${skooter.id}`} className="button negative"><Button color="danger"><FontAwesomeIcon className="fas fa-fw" icon={faTimes} width="25" height="25"/></Button></Link></td>
                    <td><Link to={`/skooters/qr/${skooter.id}`} className="button info"><Button color="info"><FontAwesomeIcon icon={faQrcode} className="fas fa-fw" width="25" height="25"/></Button></Link></td>                    
                    <td><Link to={`/skooters/travels/${skooter.id}`} className="button success"><Button color="success"><FontAwesomeIcon className="fas fa-fw" icon={faCalendarCheck} width="25" height="25"/></Button></Link></td>
                    </tr>          
                 )}  else {
                    return true;
                }         
            }
        )
    }

  
    render() {
        if(this.state.skooterCount !== 0) { 
            return (
                <div>
                    <div>
                    <Row>
                        <Col sm="12" md={{ size: 7 }}>
                            <h2 className="text-center">Skooters</h2>
                        </Col>                    
                    <Col sm="12" md={{ size: 5 }}>
                        <div className="buscar">
                    <AvForm onSubmit={this.handleSubmit}>
                        <AvGroup>
                        <AvField
                            type="text"
                            name="skooterId"
                            placeholder="Id Skooter"
                            id="skooterId"
                            onChange={this.handleChange}
                            value = {this.state.id}
                            errorMessage="Debe introducir un parámetro de búsqueda correcto"
                            validate={{
                                required: {value: true},
                                pattern: {value: '^[A-Za-z0-9]+$'},
                                minLength: {value: 2},
                                maxLength: {value: 6}                            
                            }}                  
                        />   
                        </AvGroup>
                        <Button color="danger" type="submit">Buscar Skooter</Button>
                    </AvForm> 
                    </div>
                    </Col>  
                    </Row> 
                    </div> 
                    <Table hover className="text-center">
                        <thead>
                            <tr>
                                <th>Nombre</th>                            
                                <th>Editar</th>
                                <th>Eliminar</th>
                                <th>Generar QR</th>
                                <th>Ver Viajes</th>                            
                            </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>                     
                    </Table>  
                    <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.skooterCount}
                            pageRangeDisplayed={8}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}         
                        />                             
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto skooters en una matriz
    return {
        skooters: Object.values(state.skooters)     
    };
};

export default connect(mapStateToProps, { fetchSkooters, fetchSkootersPage, fetchSkootersSearch })(SkooterEditDelete);

