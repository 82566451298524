import React from 'react';
import { connect } from 'react-redux';
import { fetchPartner, editPartner } from './actions';
import PropTypes from 'prop-types';
import { Label, Col, Row } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './PartnerList.css';

class PartnerEdit extends React.Component{
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
    };
    constructor(props) {
      super(props);
      this.state = {        
        id: '',
        idOld: '',
        name: '',
        phone: '',
        errors: null,
        fadein: false
    }      
  this.handleSubmit = this.handleSubmit.bind(this); 
  this.changeHandler = this.changeHandler.bind(this); 
}

    componentDidMount() { 
        this.props.fetchPartner(this.props.match.params.id);
    }

    componentDidUpdate(){
              
  }    

    changeHandler = ( event ) => {
      const {name, value} = event.currentTarget;
      if(name === 'partnerIdpartner') {
        this.setState({ id: value })
      }
      else if(name === 'partnerName'){
        this.setState({ name: value }) 
      } 
      else if(name === 'partnerPhone'){
        this.setState({ phone: value })    
      }     
    }

    handleSubmit(event, errors, values) {
      if(errors.length===0){
      alert('Socio modificado')
      this.props.editPartner(this.props.match.params.id, values)        
      } else alert('Error campos no válidos');
    }

    message = (message) =>{
      this.setState({      
        fadein: true,
        message: message
      })
      setTimeout (()=>{
        this.setState({
          fadein: false,
          message: ''
        })
      },3000);
    }
     
    render() {          
    if (!this.props.partner){                 
        return (<Redirect to={{
          pathname: '/Upd_Delete_partners'
        }}/>)    
    }
    else {        
        const defaultValues = {
            partnerIdpartner: this.props.partner.id, 
            partnerPhone:  this.props.partner.phone, 
            partnerName: this.props.partner.name        
        }
    return (
        <div>
            <h3>Editar un Socio</h3>
            <div> 
          <AvForm onSubmit={this.handleSubmit} model={defaultValues}>
        <AvGroup>
        <Row> 
        <Col sm={{ size: '6', offset: 0 }}> 
          <Label htmlFor="partnerIdpartner" className="block">
            Id Socio:
          </Label>
          <AvField
            type="text"
            name="partnerIdpartner"
            placeholder="ID"
            id="partnerIdpartner"
            errorMessage="Id no válido"
            value={this.state.id}
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 1},
                maxLength: {value: 3}
              }}
            onChange={this.changeHandler}            
          />
          <Label htmlFor="partnerName" className="block">
            Nombre Socio:
          </Label>
          <AvField
            type="text"
            name="partnerName"
            value = {this.state.name}
            placeholder="Nombre Socio"
            id="partnerName"
            onChange={this.changeHandler}
            errorMessage="Nombre no válido"
            validate={{
                required: {value: true},
                minLength: {value: 2},
                maxLength: {value: 20}
              }}
          />      
          </Col>
          <Col sm={{ size: '6', offset: 0 }}> 
          <Label htmlFor="partnerPhone" className="block">
            Teléfono Socio:
          </Label>
          <AvField
            type="text"
            name="partnerPhone"
            value = {this.state.phone}
            placeholder="#########"
            id="partnerPhone"
            onChange={this.changeHandler}
            errorMessage="Teléfono no válido"
            validate={{
                required: {value: true},
                pattern: {value: '^[0-9]+$'},
                minLength: {value: 9},
                maxLength: {value: 9}
              }}
          />  
          </Col>
          </Row>   
          </AvGroup> 
          <Row>
          <Col sm={{ size: '4', offset: 0 }}> 
          <div className='group_buttons'>        
            <button type="submit" className="button_transp"><FontAwesomeIcon className="fas fa-fw" icon={faEdit} width="20" height="20" color="#DC373D"/>&nbsp;Modificar Partner</button>
            
          </div>
          </Col>      
          </Row> 
          </AvForm>                        
          </div>          
        </div>    
    );}
    }
}

const mapStateToProps = (state, ownProps) => {
    return { partner: state.partners[ownProps.match.params.id]};
};

export default connect(mapStateToProps, { fetchPartner, editPartner })(PartnerEdit);