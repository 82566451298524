import { FETCH_USER_TRAVEL, FETCH_USER_TRAVEL_BEGIN, FETCH_USER_TRAVEL_FAILURE } from './action-types';
import { dbStore } from '../../firebase/firebase';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const fetchTravels = (id) => async dispatch => {
  dbStore.collection('users').doc(id).collection('travels').orderBy('start').get().then(snapshot => {
    console.log(snapshot)
    const travels = []
    snapshot.forEach(travel => {  
      //console.log(travel.id)
      //console.log('entra a viajes')
      travels.push({ key: travel.id, ...travel.data() });      
    });      
    dispatch({ type:FETCH_USER_TRAVEL, payload: travels });    
  })
}
/*export const fetchTravels = (id) => async dispatch => {
  const travels = []
    dbStore.collection('users').doc(id).collection('travels').orderBy('start').get().then(snapshot => {
      //console.log(snapshot)
      
      //var urlImg = null
      snapshot.forEach(travel => {  
       // console.log(travel.id)
       // console.log('entra a viajes')
        dbStorage.ref(`parkings/${travel.id}`).getDownloadURL().then((url) => {        
        urlImg = url        
      }).catch(function(error) {
        urlImg = ""
      });
        
        travels.push({ key: travel.id, url_Img: urlImg, ...travel.data() });      
      });      
      dispatch({ type:FETCH_USER_TRAVEL, payload: travels });    
    })
  }*/

 /* export const fetchTravel = (id) => async dispatch => {
    const travel = []
    let pictureUrl = dbStorage.ref(`parkings/${id}`).getDownloadURL().then(function(url) {
      console.log("iiiii " + url)
      travel.push({key: id, urlImg: url});
      dispatch({ type:TRAVEL, payload: travel });   

    }).catch(function(error) {
      return false;
    });;      
  }*/

  export const fetchTravelsSkooter =  (idSkooter) => async dispatch => {
    const travels = []
    var users = []
    var index = 0
    let usersRef = dbStore.collection('users')
  usersRef.get()
  .then(snapshot => {    
    snapshot.forEach(user => {  
      //console.log('usuario: '+ user.id)
      users.push(user.id)                      
    });       
  })
  await sleep(10000)  
  var interval = setInterval(async function(){
    //console.log(users[index++ % users.length], values);
    //findTravelsSkooter(users[index++ % users.length], idSkooter);
   let travelsRef = await dbStore.collection('users').doc(users[index++ % users.length]).collection('travels').where('skooter_id', '==', idSkooter)
  travelsRef.get()
  .then(snapshot => {    
      snapshot.forEach(travel => {  
       //console.log(travel.id)
       // console.log('entra a viajes')
        travels.push({ key: travel.id, ...travel.data() });  
    });   
  });     
    if(index === users.length){
      clearInterval(interval); 
      dispatch({ type:FETCH_USER_TRAVEL, payload: travels });     
   }    
  }, 5)
   
    /*dbStore.collection('users').doc().collection('travels').where('skooter_id', '==', idSkooter).get().then(snapshot => {
      console.log(snapshot)
      const travels = []
      snapshot.forEach(travel => {  
        console.log(travel.id)
        console.log('entra a viajes')
        travels.push({ key: travel.id, ...travel.data() });      
      });      
      dispatch({ type:FETCH_USER_TRAVEL, payload: travels });    
    })*/
  }

  export const fetchTravelsLoading =(idSkooter) =>{
      return dispatch => {
        dispatch(fetchTravelsBegin());
          dispatch(fetchTravelsSkooter(idSkooter))
          .catch(error =>
            dispatch(fetchTravelsFailure(error))
          );;        
      }
  }

  export const fetchTravelsLoad =(id) =>{
    return dispatch => {
      dispatch(fetchTravelsBegin());
        dispatch(fetchTravels(id))
        .catch(error =>
          dispatch(fetchTravelsFailure(error))
        );;        
    }
}

  export const fetchTravelsBegin = () => ({
    type: FETCH_USER_TRAVEL_BEGIN
  });

  export const fetchTravelsFailure = error => ({
    type: FETCH_USER_TRAVEL_FAILURE,
    payload: { error }
  });

  export async function exportarJson (values) {
    travels = []
    travelsUserDeleted = []
    //console.log("inicia en: "+ values.start_at + " termina en: "+ values.end_at)
    var startDate = values.start_at;
    startDate = startDate.split("-");
    var newStartDate = new Date( startDate[0], startDate[1] - 1, startDate[2]);
    var startTravels = newStartDate.getTime()/1000;

    var endDate = values.end_at;
    endDate = endDate.split("-");
    var newEndDate = new Date( endDate[0], endDate[1] - 1, endDate[2]);
    var endTravels = newEndDate.getTime()/1000 + 86400;
    
    //console.log(startTravels + " hasta " + endTravels)
    var users = []    
    var index = 0
    let usersRef = dbStore.collection('users')
  usersRef.get()
  .then(snapshot => {    
    snapshot.forEach(user => {  
      users.push(user.id)                      
    });       
  })
  await sleep(10000)  
  var interval = setInterval(function(){
    //console.log(users[index++ % users.length], values);
    findTravels(users[index++ % users.length], startTravels, endTravels); 
    if(index == 1){   
    findTravelsUserDeleted(startTravels, endTravels)}
    if(index === users.length){      
      clearInterval(interval);      
      //console.log(travels);       
      exportToCSV(travels, "Viajes de " + values.start_at + " a " + values.end_at)
      try {
      if (travelsUserDeleted.length > 0){
      exportToCSV(travelsUserDeleted, "Viajes usuarios eliminados de " + values.start_at + " a " + values.end_at)
    }
  } catch {
    console.log("No travel deleted")
  }
   }    
  }, 1)
}
  

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

var travels = [] 
//var cantViajes = 0
  async function findTravels(id_user, start, end) {
      let travelsRef = await dbStore.collection('users').doc(id_user).collection('travels')
    travelsRef.get()
    .then(snapshot  => {    
      try {
        snapshot.forEach(async travel => {  
          if(typeof travel.data().created_at === 'undefined'){
            //console.log("travel.data no encontrado")
          } else if(travel.data().created_at.seconds >= start && travel.data().created_at.seconds <= end) {   
          var skooter_ref = await dbStore.collection('skooters').doc(travel.data().skooter_id)
          skooter_ref.get().then( partner => {            
            travels.push({ 
              Id_skooter: undef(travel.data(),'skooter_id'),
              ID_partner: undef(partner.data(),'partner_id'),
              Id_usuario: undef(travel.data(),'user_id'),
              Id_viaje: undef(travel.data(),'id'),
              mmyyyy: undef(travel.data(),'mmyyyy'),
              Fecha_Inicio: undef(travel.data(),'start'),
              Hora_Inicio: undef(travel.data(),'start_time'),
              Fecha_Fin: undef(travel.data(),'end'),
              Hora_Fin: undef(travel.data(),'end_time'),
              Duración: undef(travel.data(),'total_time'),
              Costo: undef(travel.data(),'total_cost'),
              Balance_Inicial: undef(travel.data(),'balanceBefore'),
              Balance_Final: undef(travel.data(),'balanceAfter'),
              Cupon: undef(travel.data(),'coupon'),
              Descuento: undef(travel.data(),'discount'),
              Distancia: undef(travel.data(),'distance'),
              CO2: undef(travel.data(),'co2'),
              Desde: undef(travel.data(),'from.place.name'),
              Hasta: undef(travel.data(),'to.place.name'),
              Fuel: undef(travel.data(),'fuel'),
              Rating: undef(travel.data(),'rating'),
              Finalizaría_Fecha: undef(travel.data(),'will_end'),
              Finalizaría_Hora: undef(travel.data(),'will_end_time'),
              Static_map_url: undef(travel.data(),'static_map_urlm'),
              bill: undef(travel.data(),'bill.total'),
              partner_bill: undef2(travel.data(), partner.data(),'partner_bill')
            })
          
          })          
        }          
      })
    } catch {
        console.log("No partner ID");
      } 
    });       
  }


  var travelsUserDeleted = []

   async function findTravelsUserDeleted(start, end) {
    let travelsRef1 = await dbStore.collection('travels')
  travelsRef1.get()
  .then(snapshot1  => {    
    try {
      snapshot1.forEach(async travel1 => {  
        if(typeof travel1.data().created_at === 'undefined'){
          //console.log("travel.data no encontrado")
        } else if(travel1.data().created_at.seconds >= start && travel1.data().created_at.seconds <= end) {          
          travelsUserDeleted.push({ 
            Id_skooter: undef(travel1.data(),'skooter_id'),            
            Id_usuario: undef(travel1.data(),'user_id'),
            Id_viaje: undef(travel1.data(),'id'),
            mmyyyy: undef(travel1.data(),'mmyyyy'),
            Fecha_Inicio: undef(travel1.data(),'start'),
            Hora_Inicio: undef(travel1.data(),'start_time'),
            Fecha_Fin: undef(travel1.data(),'end'),
            Hora_Fin: undef(travel1.data(),'end_time'),
            Duración: undef(travel1.data(),'total_time'),
            Costo: undef(travel1.data(),'total_cost'),
            Balance_Inicial: undef(travel1.data(),'balanceBefore'),
            Balance_Final: undef(travel1.data(),'balanceAfter'),
            Cupon: undef(travel1.data(),'coupon'),
            Descuento: undef(travel1.data(),'discount'),
            Distancia: undef(travel1.data(),'distance'),
            CO2: undef(travel1.data(),'co2'),
            Desde: undef(travel1.data(),'from.place.name'),
            Hasta: undef(travel1.data(),'to.place.name'),
            Fuel: undef(travel1.data(),'fuel'),
            Rating: undef(travel1.data(),'rating'),
            Finalizaría_Fecha: undef(travel1.data(),'will_end'),
            Finalizaría_Hora: undef(travel1.data(),'will_end_time'),
            Static_map_url: undef(travel1.data(),'static_map_urlm'),
            bill: undef(travel1.data(),'bill.total')
          })                  
      }          
    })
      } catch {
        console.log("No partner ID");
      }  
  });       
}


  const undef2 = (travel, partner, param) =>{
    var param_value = ''
    if (param === 'partner_bill'){
      try {
        const fuel = parseFloat(travel.fuel).toFixed(2)
        if (partner.partner_id > 1){
          if (fuel == 60){
            param_value = 10
          }
          if (fuel == 120){
            param_value = 15
          }         
          if (fuel == 240){
            param_value = 25
          }
       }
      }
      catch{ 
        param_value = ''
      }       
    } 
    return param_value; 
  }

  const undef = (travel, param) =>{
    var param_value = ''
    if (param === 'skooter_id'){      
        try {
          param_value = travel.skooter_id
        }
        catch{ 
          param_value = ''
        }
    }

    if (param === 'partner_id'){
      try {        
        param_value = travel.partner_id
      }
      catch {
        param_value = ''
      }
    }

    if (param === 'user_id'){
       try {
        param_value = travel.user_id
      } 
      catch{ 
        param_value = ''
      }    
    }

    if (param === 'id'){
      try {
        param_value = travel.id
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'mmyyyy'){
      try {
        param_value = travel.mmyyyy
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'start'){
      try {
        param_value = travel.start.toDate().toLocaleDateString()               
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'start_time'){
      try {
        param_value = travel.start.toDate().toLocaleTimeString()                
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'end'){
      try {
        param_value = travel.end.toDate().toLocaleDateString() 
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'end_time'){
      try {
        param_value = travel.end.toDate().toLocaleTimeString()
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'total_time'){
      try {
        param_value = travel.total_time
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'total_cost'){
      try {
        param_value = parseFloat(travel.total_cost).toFixed(2)
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'balanceBefore'){
      try {
        param_value = parseFloat(travel.balanceBefore).toFixed(2)
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'balanceAfter'){
      try {
        param_value = parseFloat(travel.balanceAfter).toFixed(2)
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'coupon'){
      try {
        param_value = travel.coupon
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'discount'){
      try {
        param_value = travel.discount
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'distance'){
      try {
        param_value = parseFloat(travel.distance).toFixed(2)
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'co2'){
      try {
        param_value = parseFloat(travel.co2).toFixed(2)
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'from.place.name'){
      try {
        param_value = travel.from.place.name
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'to.place.name'){
      try {
        param_value = travel.to.place.name
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'fuel'){
      try {
        param_value = parseFloat(travel.fuel).toFixed(2)
      }
      catch{ 
        param_value = ''
      }       
    }

    if (param === 'rating'){
      try {
        param_value = travel.rating
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'will_end'){
      try {
        param_value = travel.will_end.toDate().toLocaleDateString() 
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'will_end_time'){
      try {
        param_value = travel.will_end.toDate().toLocaleTimeString()
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'static_map_urlm'){
      try {
        param_value = travel.static_map_urlm
      } 
      catch{ 
        param_value = ''
      }      
    }

    if (param === 'bill.total'){
      try {
        param_value = parseFloat(travel.bill.total).toFixed(2)
      }
      catch{ 
        param_value = ''
      }       
    }      

    return param_value;
  }
      
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        travels = [];
        csvData = null;
    }    

    
    //const maptoarray