import _ from 'lodash';
import {
    FETCH_PLACE,
    FETCH_PLACES,
    CREATE_PLACE,
    DELETE_PLACE
} from './action-types';

export function placesReducer (state = {}, action) {
    switch (action.type) {
        case FETCH_PLACES:
            return { state, ..._.mapKeys(action.payload, 'id') };
        case FETCH_PLACE:
            return { ...state, [action.payload.id]: action.payload };     
        case CREATE_PLACE:
            return { ...state, [action.payload.id]: action.payload }; 
        case DELETE_PLACE:
            return _.omit(state, action.payload);
        default:
            return state;
    }
}