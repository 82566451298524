/*import React from 'react';
import ReactDOM from 'react-dom';

const Modal = props => {
    return ReactDOM.createPortal(
        <div onClick={props.onDismiss} className="ui dimmer modals visible active">
            <div onClick={e => e.stopPropagation()} className="ui standard modal visible active">
                <div className="header">{ props.title }</div>
                <div className="content">
                    {props.content}
                </div>
                <div className="actions">
                    {props.actions}    
                </div>
            </div>
        </div>,
        document.querySelector('#modal')
    );
};

export default Modal;*/

import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalDelete = (props) => {
  
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);

  return (
    <div>      
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
        <ModalBody>
            {props.content}
        </ModalBody>
        <ModalFooter>
          {props.actions} 
          {setModal}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalDelete;