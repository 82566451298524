import { FETCH_USERS, FETCH_USER, DELETE_USER, SAVE_ERROR } from './action-types';
//import history from "../../history";
import { dbStorage, dbStore } from '../../firebase/firebase';


export function saveUser (error) {
    return{
      type: SAVE_ERROR,
      payload: error
    };
}

export function createUser  (formValues) {
    return dispatch => {
        let user = {
            id: '',
            name: formValues.userName,
            email: formValues.userEmail,
            balance: formValues.userBalance,
            upv: formValues.userUpv,
            stripeCustomerId: ''
          };      
          dbStore.collection('users').add(user).then(function(docRef) {
            var userRef = dbStore.collection('users').doc(docRef.id);
              userRef.update({
                id: docRef.id
              })
        }).catch(function(error) {
          console.error("Error adding document: ", error);
      });
        }            
};

export const editUser = (id, formValues) => dispatch => {  
  var userRef = dbStore.collection('users').doc(id);
  userRef.update({
    name: formValues.userName,
    email: formValues.userEmail,
    balance: formValues.userBalance,
    upv: formValues.userUpv   
  })
  // Omite el usuario que se modifico para cargar el listado con los 
  // datos actualizados
    dispatch({ type:DELETE_USER, payload: id });
  }

export const fetchUsers = () => dispatch => {
  let usersRef = dbStore.collection('users').orderBy('email').limit(10)
  usersRef.get()
  .then(snapshot => {
    const users = []
    snapshot.forEach(user => {  
      users.push({ key: user.id, ...user.data() });
    });
    dispatch({ type:FETCH_USERS, payload: users });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}

export const countUsers = async() => {
  var number = 0
  await dbStore.collection('users').get()
  .then(snapshot => {    
    number = snapshot.size
  })  
  return number;
}

export const fetchUsersPage = (page) => dispatch => {
  const pageSearch = (page-1) * 10
  let usersRef = dbStore.collection('users').orderBy('email')  
  usersRef.get()
  .then(snapshot => {
    var firstVisible = snapshot.docs[pageSearch];
    let userPaged = dbStore.collection('users').orderBy('email').startAt(firstVisible.data().email).limit(10)
    userPaged.get()
    .then( snapshotUser => {
    const users = []
    snapshotUser.forEach(user => { 
      users.push({ key: user.id, ...user.data() });
    });
    dispatch({ type:FETCH_USERS, payload: users });    
  })})
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}


export const fetchUsersSearch = (email) => dispatch => {
  let usersRef = dbStore.collection('users').where('email', '>=', email).where('email', '<=', email+"z")
  usersRef.get()
  .then(snapshot => {
    const users = []
    snapshot.forEach(user => {  
      users.push({ key: user.id, ...user.data() });
    });
    dispatch({ type:FETCH_USERS, payload: users });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });    
}

export const fetchUsersSearchId = (id) => dispatch => {
  let usersRef = dbStore.collection('users').where('id', '>=', id).where('id', '<=', id+"z")
  usersRef.get()
  .then(snapshot => {
    const users = []
    snapshot.forEach(user => {  
      users.push({ key: user.id, ...user.data() });
    });
    dispatch({ type:FETCH_USERS, payload: users });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });    
}


export const fetchUser = (id) => async dispatch => {
  dbStore.collection('users').doc(id).get().then((doc) => {
    // check if the document exists
    if (doc.exists) {
      const response = doc.data();
      console.log(doc.data())
      dispatch({ type: FETCH_USER, payload: response });      
    } else {
      throw new Error('No Such Document');
    }
})    
}

export const deleteUser = (id) => async dispatch => {
  const travelsRef = await dbStore.collection('users').doc(id).collection('travels');
  travelsRef.get()
    .then(snapshot  => {    
        snapshot.forEach(async travel => {         
            console.log("TRAVEL " + travel)           
            await dbStore.collection('travels').add(travel.data());
            //await this.fireService.create('/travels',travel.data(),travel.id)               
      });   
    })
  //await dbStore.collection('travels').add(travelsRef); 
  dbStore.collection('users').doc(id).delete()
  dispatch({ type: DELETE_USER, payload: id });    
}


export function exportarJson () {
  const users = []
  let usersRef = dbStore.collection('users').where('sendInfo', '==', true).orderBy('email')
 usersRef.get()
  .then(snapshot => {    
      snapshot.forEach(user => {  
        //console.log(user.data().email)
      users.push(user.data().email)
    });    
          var jso = JSON.stringify(users)
          var data = new Blob([jso], {type: 'text/json'});
          var jsonURL = window.URL.createObjectURL(data);
          var tempLink = document.createElement('a');
          tempLink.href = jsonURL;
          tempLink.setAttribute('download', 'usuarios.json');
          tempLink.click();        
      
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });   //dispatch({ type:FETCH_USERS, payload: users });    
  
}

export async function userEmail (userId) {
  let usersRef = await dbStore.collection('users').doc(userId).get().then((doc) => {
    // check if the document exists
    if (doc.exists) {
      const userEmail = doc.data().email;    
      return userEmail;     
    }
})
}