import { CREATE_PARTNER, FETCH_PARTNERS, FETCH_PARTNER, DELETE_PARTNER, SAVE_ERROR } from './action-types';
import history from "../../history";
import { dbStore } from '../../firebase/firebase';


export function savePartner (error) {
    return{
      type: SAVE_ERROR,
      payload: error
    };
}

export function createPartner  (formValues) {
    return dispatch => {
        let partner = {
            id: formValues.idpartner,
            name: formValues.partnerName,
            phone: formValues.telephone,            
          };      
          dbStore.collection('partners').doc(formValues.idpartner).set(partner).catch(error => dispatch(savePartner(error)));        
        }            
};

export function editPartner  (idOld, values) {
  if(idOld !== values.partnerIdpartner){
  return dispatch => {
        let partnerRef = dbStore.collection('partners').doc(idOld);
          partnerRef.get()
          .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
        dbStore.collection('partners').doc(values.partnerIdpartner).set(doc.data())
        let refPartner = dbStore.collection('partners').doc(values.partnerIdpartner)       
        refPartner.set({id: values.partnerIdpartner, name: values.partnerName, phone: values.partnerPhone}, { merge: true }).then(dbStore.collection('partners').doc(idOld).delete()) 
      dispatch({ type: DELETE_PARTNER, payload: idOld });   
      }
  })
  .catch(err => {
    console.log('Error getting document', err);
  });    
}  
  } else {    
    return dispatch => {
      let refPartner = dbStore.collection('partners').doc(values.partnerIdpartner)       
        refPartner.set({ name: values.partnerName, phone: values.partnerPhone }, { merge: true })      
    dispatch({ type: DELETE_PARTNER, payload: idOld });   
    }  
  }
};  

export const fetchPartners = () => dispatch => {
  let partnersRef = dbStore.collection('partners').orderBy('id').limit(10)
let allPartners = partnersRef.get()
  .then(snapshot => {
    console.log(snapshot)
    const partners = []
    snapshot.forEach(partner => {  
      partners.push({ key: partner.id, ...partner.data() });
    });
    dispatch({ type:FETCH_PARTNERS, payload: partners });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}

export const fetchPartnersPage = (page) => dispatch => {
  console.log('pagina '+ page)
  const pageSearch = (page-1) * 10
  console.log('search '+pageSearch)
    let partnersRef = dbStore.collection('partners').orderBy('id')  
let allPartners = partnersRef.get()
  .then(snapshot => {
    var firstVisible = snapshot.docs[pageSearch];
    let partnerPaged = dbStore.collection('partners').orderBy('id').startAt(firstVisible.data().id).limit(10)
    let allPagedPartners = partnerPaged.get()
    .then( snapshotPartner => {
    const partners = []
    snapshotPartner.forEach(partner => { 
      console.log(partner.id) 
      partners.push({ key: partner.id, ...partner.data() });
    });
    dispatch({ type:FETCH_PARTNERS, payload: partners });    
  })})
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}


export const fetchPartnersSearch = (name) => dispatch => {
  let partnersRef = dbStore.collection('partners').where('name', '>=', name).where('name', '<=', name+"z")
let allPartners = partnersRef.get()
  .then(snapshot => {
    const partners = []
    snapshot.forEach(partner => {  
      partners.push({ key: partner.id, ...partner.data() });
    });
    dispatch({ type:FETCH_PARTNERS, payload: partners });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}


export const fetchPartner = (id) => async dispatch => {
  let responsePartner = dbStore.collection('partners').doc(id).get().then((doc) => {
    // check if the document exists
    if (doc.exists) {
      const response = doc.data();
      console.log(doc.data())
      dispatch({ type: FETCH_PARTNER, payload: response });      
    } else {
      throw new Error('No Such Document');
    }
})    
}

export const deletePartner = (id) => async dispatch => {
  let deletePartner = dbStore.collection('partners').doc(id).delete()
  dispatch({ type: DELETE_PARTNER, payload: id });    
}


export const countPartners = async() => {
  var number = 0
  await dbStore.collection('partners').get()
  .then(snapshot => {    
    number = snapshot.size
  })  
  return number;
}


