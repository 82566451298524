import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import NavBar from './Navbar';
import { Switch, Route } from 'react-router-dom';
import SkooterCreate from '../skooters/SkooterCreate';
import SkooterEdit from '../skooters/SkooterEdit';
import SkooterDelete from '../skooters/SkooterDelete';
import SkooterList from '../skooters/SkooterList';
import SkooterEditDelete from '../skooters/SkooterEditDelete';
import SkooterQR from '../skooters/SkooterQR';
import UserCreate from '../users/UserCreate';
import UserEdit from '../users/UserEdit';
import UserDelete from '../users/UserDelete';
import UserList from '../users/UserList';
import UserEditDelete from '../users/UserEditDelete';
import UsersFound from '../users/UsersFound';
import TravelsList from '../travels/TravelsList';
import AllTravels from '../travels/AllTravels'
//import PlaceCreate from '../places/PlaceCreate';
//import PlaceList from '../places/PlacesList';
//import PlaceEditDelete from '../places/PlaceEditDelete';
//import PlaceEdit from '../places/PlaceEdit';
//import PlaceDelete  from '../places/PlaceDelete';
import PartnerCreate from '../partners/PartnerCreate';
import PartnerList from '../partners/PartnerList';
import PartnerEditDelete from '../partners/PartnerEditDelete';
import PartnerEdit from '../partners/PartnerEdit';
import PartnerDelete from '../partners/PartnerDelete';
import Salir from '../../auth/salir';
import SkootersMap from '../skooters/SkootersMap';
import SkooterTravels from '../skooters/SkooterTravels';
import TravelPicture from '../travels/TravelPicture';

export default props => (
    <Container fluid className={classNames('content', {'is-open': props.isOpen})}>
      <NavBar toggle={props.toggle}/>
      <Switch>
        <Route exact path="/" component={ SkootersMap } />
        <Route exact path="/salir" component={ Salir } />
        <Route exact path="/Pages" component={() => "Pages" } />
        <Route exact path="/faq" component={() => "FAQ" } />
        <Route exact path="/contact" component={() => "Contact" } />
        <Route exact path="/Add_user" component={ UserCreate } />
        <Route exact path="/List_users" component={ UserList } />
        <Route exact path="/Upd_Delete_users" component={ UserEditDelete } />
        <Route exact path="/users/edit/:id" component={ UserEdit }/>
        <Route exact path="/users/delete/:id" component={ UserDelete }/>
        <Route exact path="/users/found/:email" component={ UsersFound }/>
        <Route exact path="/viajes/list/:id" component={ TravelsList }/>
        <Route exact path="/viajes/AllTravels" component={ AllTravels }/>
        <Route exact path="/Add_skooter" component={ SkooterCreate } />
        <Route exact path="/List_skooters" component={ SkooterList } />
        <Route exact path="/Upd_Delete_skooters" component={ SkooterEditDelete } />
        <Route exact path="/skooters/edit/:id" component={ SkooterEdit }/>
        <Route exact path="/skooters/delete/:id" component={ SkooterDelete }/>
        <Route exact path="/skooters/qr/:id" component={ SkooterQR }/>
        <Route exact path="/skooters/travels/:id" component={ SkooterTravels }/>
        <Route exact path="/travel/:id" component={ TravelPicture }/>
        <Route exact path="/Add_places" component={ () => "Agregar Lugar" } />
        <Route exact path="/List_places" component={ () => "Listar Lugares" } />
        <Route exact path="/Upd_Delete_places" component={ () => "Actualizar Lugares" } />  
        <Route exact path="/places/edit/:id" component={ () => "Editar" }/>
        <Route exact path="/places/delete/:id" component={ () => "Eliminar" }/>    
        <Route exact path="/Add_partners" component={ PartnerCreate } />
        <Route exact path="/List_partners" component={ PartnerList } />
        <Route exact path="/Upd_Delete_partners" component={ PartnerEditDelete } />  
        <Route exact path="/partners/edit/:id" component={ PartnerEdit }/>
        <Route exact path="/partners/delete/:id" component={ PartnerDelete }/>  
      </Switch>
    </Container>
)
