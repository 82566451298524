import React, {useState} from 'react';
//import { connect } from 'react-redux';
import { Button, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { AvForm, AvField } from 'availity-reactstrap-validation';
//import { signInWithGoogle, signInWithEmailPassword } from '../../auth/actions';
import { signInWithGoogle } from "../../firebase/firebase";
import { firebaseAuth } from "../../firebase/firebase";
import './sign-in-page.css';
import logo from '../../assets/img/logo-red.svg';

const SignIn = () => {
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [error, setError] = useState(null);
  
      const signInWithEmailAndPasswordHandler = (event,email, password) => {
          event.preventDefault();
          firebaseAuth.signInWithEmailAndPassword(email, password).catch(error => {
          setError("Usuario y contraseña no válidos");
            console.error("Error signing in with password and email", error);
          });
        };
        
        const onChangeHandler = (event) => {
            const {name, value} = event.currentTarget;
          
            if(name === 'userEmail') {
                setEmail(value);
            }
            else if(name === 'userPassword'){
              setPassword(value);
            }
        };     

     
      return (
    <div>
      <div className="sign-in">
      <img className="mx-auto d-block" src={logo} width="200px" height="50px" alt="logo de euskooter"/>
      <br></br>   
      {error !== null && <div>{error}</div>}       
        <AvForm>
        <FormGroup>
          <Label htmlFor="userEmail" className="block">
            Email:
          </Label>
          <AvField
            type="email"
            name="userEmail"
            placeholder="ejemplo123@gmail.com"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
            errorMessage="Correo no válido"
            validate={{
              required: {value: true},              
              minLength: {value: 3},
              maxLength: {value: 50}
            }}
          />
          </FormGroup>
          <FormGroup>
          <Label htmlFor="userPassword" className="block">
            Password:
          </Label>
          <AvField
            type="password"
            name="userPassword"
            placeholder="Contraseña"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
            errorMessage="La contraseña debe tener al menos 6 caracteres."
            validate={{
              required: {value: true},
              minLength: {value: 6},
              maxLength: {value: 20}
            }}
          />
          </FormGroup>
        <Button color="secondary" type="submit" className="sign-in__button" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>Correo y Contraseña</Button>
        </AvForm>
        <Button color="danger" className="sign-in__button_red" onClick={() => { signInWithGoogle();}}><FontAwesomeIcon icon={faGoogle} className="mr-2"/>Entrar con Google</Button>{' '}
      </div>        
    </div>
  );
};



export default SignIn;