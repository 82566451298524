import _ from 'lodash';
import {    
    FETCH_USER_TRAVEL,
    FETCH_USER_TRAVEL_BEGIN,
    FETCH_USER_TRAVEL_FAILURE    
} from './action-types';

const initialState = {
    items: [],
    loading: false,
    error: null
  };

export function travelReducer (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_TRAVEL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case FETCH_USER_TRAVEL:
            return { 
                state, 
                items: action.payload,
                loading: false
             };  
        case FETCH_USER_TRAVEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                items: []
            };     
        default:
            return state;
    }
}