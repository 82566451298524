import firebase from 'firebase';
import { firebaseAuth, dbStore } from '../firebase/firebase';
import {
  INIT_AUTH,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS
} from './action-types';


function authenticate(provider) {
  return dispatch => {
    firebaseAuth.signInWithPopup(provider)
      .then(result => dispatch(admin(result)))
      .catch(error => dispatch(signInError(error)));
  };
}

function admin(result) {  
  console.log(result.user.email)
  return dispatch => {
  let usersRef = dbStore.collection('users');
  usersRef.where('email', '==', result.user.email).get()
  .then(snapshot => {
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }
    snapshot.forEach(doc => {      
      if(doc.data().roles.admin === true){
        console.log('es administrador');
        dispatch(signInSuccess(result))       
      } else {
        console.log('No es administrador');
      }      
    })
  })
  .catch(error => dispatch(signInError(error)));
}}

export function getAdmin(){
  
}

export function initAuth(user) {
  return {
    type: INIT_AUTH,
    payload: user
  };
}


export function signInError(error) {
  console.log('error al acceder')
  return {
    type: SIGN_IN_ERROR,
    payload: error
  };
}


export function signInSuccess(result) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: result.user
  };
}

export function signInWithGoogle() {
  return authenticate(new firebase.auth.GoogleAuthProvider());
}

export function signInWithEmailPassword(email,password) {
  return dispatch => {
    firebaseAuth.signInWithEmailAndPassword(email,password)
      .then(result => dispatch(admin(result)))
      .catch(error => dispatch(signInError(error)));
  };
}


export function signOut() {
  return dispatch => {
    firebaseAuth.signOut()
      .then(() => dispatch(signOutSuccess()));
  };
}


export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS
  };
}
