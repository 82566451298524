import { FETCH_SKOOTERS, FETCH_SKOOTER, DELETE_SKOOTER, SAVE_ERROR } from './action-types';
//import { formValues } from 'redux-form';
//import history from "../../history";
import { dbStorage, dbStore, funct } from '../../firebase/firebase';
//import React, { useContext, useEffect, useState } from 'react'
import fetch from 'node-fetch';

import "firebase/functions";
const dJSON = require('dirty-json')



export function saveSkooter (error) {
    return{
      type: SAVE_ERROR,
      payload: error
    };
}

export function createSkooter  (formValues) {
    return dispatch => {
        let skooters = {
            id: formValues.skooterIdskooter,
            iot: formValues.skooterIotskooter,
            partner_id: formValues.selectPartner,
            unlock_code: formValues.skooterUnlockCode,
            bateria: '',
            battery_level: '',
            cobertura: '',
            ultima_latitud: '',
            ultima_longitud: ''
          }; 
          let travelSkooter = {
            id: formValues.skooterIdskooter
          }     
           dbStore.collection('skooters').doc(formValues.skooterIdskooter).set(skooters).catch(error => dispatch(saveSkooter(error)));
           dbStore.collection('skooters').doc(formValues.skooterIdskooter).set({ status: { code: 'locked' } }, { merge: true });
           dbStore.collection('travelSkooter').doc(formValues.skooterIdskooter).set(travelSkooter);
        }            
};

export function editSkooter  (idOld, values) {
  if(idOld !== values.skooterIdskooter){
  return dispatch => {
        let skooterRef = dbStore.collection('skooters').doc(idOld);
         skooterRef.get()
          .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
         } else {
        dbStore.collection('skooters').doc(values.skooterIdskooter).set(doc.data())
        let refSkooter = dbStore.collection('skooters').doc(values.skooterIdskooter)       
        refSkooter.set({id: values.skooterIdskooter, iot: values.skooterIotskooter, unlock_code: values.skooterUnlockCode, partner_id: values.selectPartner}, { merge: true }).then(dbStore.collection('skooters').doc(idOld).delete()) 
      dispatch({ type: DELETE_SKOOTER, payload: idOld });   
      }
  })
  .catch(err => {
    console.log('Error getting document', err);
  });    
}  
  } else {    
    return dispatch => {
      let refSkooter = dbStore.collection('skooters').doc(values.skooterIdskooter)       
        refSkooter.set({ iot: values.skooterIotskooter, unlock_code: values.skooterUnlockCode, partner_id: values.selectPartner }, { merge: true })      
    dispatch({ type: DELETE_SKOOTER, payload: idOld });   
    }  
  }
};


export const fetchSkooters = () => dispatch => {
  let skootersRef = dbStore.collection('skooters').orderBy('id').limit(10)
  skootersRef.get()
  .then(snapshot => {
    const skooters = []
    snapshot.forEach(skooter => {      
      skooters.push({ key: skooter.id, ...skooter.data() });
    });
    dispatch({ type:FETCH_SKOOTERS, payload: skooters });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}

export const countSkooters = async() => {
  var number = 0
  await dbStore.collection('skooters').get()
  .then(snapshot => {    
    number = snapshot.size
  })  
  return number;
}

export const fetchSkootersPage = (page) => dispatch => {
  const pageSearch = (page-1) * 10
  let skootersRef = dbStore.collection('skooters').orderBy('id')  
  skootersRef.get()
  .then(snapshot => {
    var firstVisible = snapshot.docs[pageSearch];
    let skooterPaged = dbStore.collection('skooters').orderBy('id').startAt(firstVisible.data().id).limit(10)

    skooterPaged.get()
    .then( snapshotSkooter => {
    const skooters = []
    snapshotSkooter.forEach(skooter => { 
      skooters.push({ key: skooter.id, ...skooter.data() });
    });
    dispatch({ type:FETCH_SKOOTERS, payload: skooters });    
  })})
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}

export const fetchSkootersSearch = (id) => dispatch => {
  let skootersRef = dbStore.collection('skooters').orderBy('id').where('id', '>=', id).where('id', '<=', id+"z")
  skootersRef.get()
  .then(snapshot => {
    const skooters = []
    snapshot.forEach(skooter => {      
      skooters.push({ key: skooter.id, ...skooter.data() });
    });
    dispatch({ type:FETCH_SKOOTERS, payload: skooters });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}

export const fetchSkooter = (id) => async dispatch => {
    dbStore.collection('skooters').doc(id).get().then((doc) => {
    // check if the document exists
    if (doc.exists) {
      const response = doc.data();
      dispatch({ type: FETCH_SKOOTER, payload: response });      
    } else {
      throw new Error('No Such Document');
    }
})    
}

export const deleteSkooter = (id) => async dispatch => {
  dbStore.collection('skooters').doc(id).delete();    
  dispatch({ type: DELETE_SKOOTER, payload: id });    
}

export const fetchAllSkooters = () => dispatch => {
  let skootersRef = dbStore.collection('skooters').orderBy('id')
  skootersRef.get()
  .then(snapshot => {
    const skooters = []
    snapshot.forEach(skooter => {      
      skooters.push({ key: skooter.id, ...skooter.data() });
    });
    dispatch({ type:FETCH_SKOOTERS, payload: skooters });    
  })
  .catch(err => {
    console.log('Error getting documents', err);
  });  
  
}

/* const commandSkooterCall = (skooterId, command) => {
  return fetch('https://www.seguridadcentinela.com/mapa/api_send_VPN_command_segway.php', {
method: 'POST',
headers: {
  "content-type": "application/x-www-form-urlencoded"
},
body: `datos={
  "token_usuario": "E1838643773817006790",
  "id_centinela": "${skooterId}",
  "comando": "${command}"
}`
})
.then((res) => res.json())
.then((res) => { 
  if(res.code === 0){
  if (command === "PA0"){
    alert('Modo pánico desactivado: ' + skooterId)
  } else if (command === "PA1"){
    alert('Modo pánico activado: ' + skooterId)
  } else if (command === "STA"){
    alert('Euskooter desbloqueado: ' + skooterId)
  } else if (command === "STO"){
    alert('Euskooter bloqueado: ' + skooterId)
  } else if (command === "BEL"){
    console.log("RES" + res.datos_centinela.bateria_patinete.valor)
    let refSkooter = dbStore.collection('skooters').doc(res.id_centinela)       
        refSkooter.set({
          ultima_latitud: res.datos_centinela.ultima_latitud,
          ultima_longitud: res.datos_centinela.ultima_longitud,
          velocidad_instantanea: res.datos_centinela.velocidad_instantanea,
          precision: res.datos_centinela.precision,
          cobertura: res.datos_centinela.cobertura,
          bateria: res.datos_centinela.bateria,
          tipo_dato: res.datos_centinela.tipo_dato,
          satelites: res.datos_centinela.satelites,
          battery_level: res.datos_centinela.bateria_patinete.valor,
          fecha: res.datos_centinela.fecha
        }, { merge: true })
    alert('Euskooter Actualizado: ' + skooterId)
  } 
} 
return res
})
};

export const commandSkooter = async (skooterId, comando) => {
  
  let res = await commandSkooterCall(skooterId, comando) 
  if (res.code !== 0) 
     {
       alert('No se ha podido ejecutar, error de conexión con centinela')
      }  
  return res
}
//////////////////////////////////////////////////////////

const accessToken = async () => {
  const segway = (await dbStore.doc('configuration/segway').get()).data()
  if(!segway) {
    console.log("no se ha encontrado el token");
  } else {
    console.log('este es el token: '+ segway.access_token);
    return segway.access_token;
  }
}
*/
export const commandSkooterCall = async (iot, command, id) => {
  
var data = JSON.stringify({data:{ "iot": iot, "command": command }})
  
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: data,
  redirect: 'follow'
};

await fetch("https://us-central1-euskooters.cloudfunctions.net/dashboardCommand", requestOptions)
.then((res) => res.json())
.then((res) => {
  if (res.result.code === 0){
    if (command === "PA0"){
      alert('Modo pánico desactivado: ' + id)
    } else if (command === "PA1"){
      console.log('Modo pánico activado: ' + id )
    } else if (command === "STA"){
      alert('Euskooter desbloqueado: ' + id )
    } else if (command === "STO"){
      alert('Euskooter bloqueado : ' + id)
    } else if (command === "BEL"){
      let refSkooter = dbStore.collection('skooters').doc(id)       
          refSkooter.set({
            speed: res.result.data.speed,
            odometer: res.result.data.odometer,
            battery_level: res.result.data.powerPercent,
            networkSignal: res.result.data.networkSignal,
            remainingRange: res.result.data.remainingRange,
            totalRidingSecs: res.result.data.totalRidingSecs          
          }, { merge: true })
      alert('Euskooter Actualizado: ' + id)
    } 
    return res.result;
  } else {
    alert('No se ha podido ejecutar, error de conexión con Segway')     
  }
}).catch(error => alert(' error de conexión con Segway' + error));
  /*var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {data}
  }
  /*
  const dashboardCommand = funct.httpsCallable('dashboardCommand');
          await dashboardCommand(data)
          .then((res) => res.json())
          .then((res) => {
            console.log({iot, comando, res})
            return res
          })
   */
  
         // fetch('https://us-central1-euskooters.cloudfunctions.net/dashboardCommand')

 /* if(comando == 'STA' && iot) {

    console.log("entra al DESBLOQUEO");
    return fetch('https://us-central1-euskooters.cloudfunctions.net/dashboardCommand', requestOptions)
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    })
    .catch(error => {
      throw new Error(error)
    })
  }

  if(comando == 'STO' && iot) {
    console.log("entra al BLOQUEO");
    return fetch('https://us-central1-euskooters.cloudfunctions.net/dashboardCommand', requestOptions)
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    })
    .catch(error => {
      throw new Error(error)
    })
  }
/*
  var getOptions = {
    method: 'GET',
    headers: {
        'Authorization' : 'bearer ' + `${access_token}`,
      }
  }
  if(comando == 'BEL' && iot) {
    console.log('entra al comando BEL');
    return fetch(`https://eu-api.segwaydiscovery.com/api/v2/vehicle/query/current/status?iotCode=${iot}`, getOptions)
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    })
    .catch(error => {
      throw new Error(error)
    })
  }

  if(comando == 'BAT' && iot) {    
    console.log('entra al comando BAT');
    return fetch(`https://eu-api.segwaydiscovery.com/api/v2/vehicle/query/current/battery-info?iotCode=${iot}`, getOptions)
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    })
    .catch(error => {
      throw new Error(error)
    })
  }

  if(comando == 'LOC' && iot) {
    return fetch(`https://eu-api.segwaydiscovery.com/api/v2/vehicle/query/current/location?iotCode=${iot}`, getOptions)
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    })
    .catch(error => {
      throw new Error(error)
    })
  }

  if(comando == 'PA1' && iot) {
    return fetch('https://eu-api.segwaydiscovery.com/api/v2/vehicle/control/sound', {
      method: 'POST',
      headers: {
        'Authorization' : `'bearer ' + '${access_token}'`,
        'Content-Type': 'application/json'
      },
      body: `{
        "iotCode": "${iot}",
        "controlType" : 1
      }`
    })
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    })
    .catch(error => {
      throw new Error(error)
    })
  }

  if(comando == 'PIT' && iot) {
    return fetch('https://eu-api.segwaydiscovery.com/api/v2/vehicle/control/sound', {
      method: 'POST',
      headers: {
        'Authorization' : `'bearer ' + '${access_token}'`,
        'Content-Type': 'application/json'
      },
      body: `{
        "iotCode": "${iot}",
        "controlType" : 2
      }`
    })
    .then((res) => res.json())
    .then((res) => {
      console.log({iot, comando, res})
      return res
    }).catch(error => {
      throw new Error(error)
    })
  }

  */
}

/*export const commandSkooter = async (skooterId, comando) => {
  //try {
  const res = await commandSkooterCall(skooterId, comando); 
    console.log(res);
  if (res.code !== 0) 
     {
       alert('No se ha podido ejecutar, error de conexión con Segway')
      }  
  return res
  /*}
  catch (error) {
    alert(' error de conexión con Segway' + error)
  }
}
*/
export const loadPartners = async () => {

}
 
