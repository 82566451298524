import { Record } from 'immutable';
import { INIT_AUTH, SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS, SIGN_IN_ERROR } from './action-types';


export const AuthState = new Record({
  authenticated: false,
  error: null,
  id: null
});


export function authReducer(state = new AuthState(), {payload, type}) {
  switch (type) {
    case INIT_AUTH:
    case SIGN_IN_SUCCESS:
      return state.merge({
        authenticated: !!payload,
        id: payload ? payload.uid : null
      });
    case SIGN_OUT_SUCCESS:
      return new AuthState();
    case SIGN_IN_ERROR:
      console.log('entro al reducer')
      return state.merge({
        authenticated: false,
        error: payload,
        id: null
      });
       

    default:
      return state;
  }
}
