import mapboxgl from 'mapbox-gl';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAllSkooters /*, commandSkooter, fetchSkooter , fetchSkootersPage, fetchSkootersSearch*/ } from './actions';
import './SkooterList.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapIcon from '../../assets/img/marker-full.svg';
import inactive from '../../assets/img/marker-inactive.svg';
import low from '../../assets/img/marker-low.svg';
import medium from '../../assets/img/marker-medium.svg';
import unlocked from '../../assets/img/marker-euskooters-green.svg';
import { dbStore } from '../../firebase/firebase';
//import { Button } from 'reactstrap';
//import history from '../../history';
//import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
//import { Table, Button, Row, Col  } from 'reactstrap';
//import { Link, Redirect } from 'react-router-dom';



class SkootersMap extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            lng: -3.0012,
            lat: 43.3305,
            zoom: 10,
            id: '',
            skooters_new: null,
            intervalMap: null,
            };    
            this.getSkooters = this.getSkooters.bind(this); 
            this.loadMap = this.loadMap.bind(this);
              
      }

     
    componentDidMount(){
      this.props.fetchAllSkooters(); 
      this.loadMap();                     
    }

    componentWillUnmount(){
      clearInterval(this.state.intervalMap);
    }

    loadMap(){
        mapboxgl.accessToken = 'pk.eyJ1IjoiZXVza29vdGVycyIsImEiOiJjazZjNG9ydTYweGh6M2ttZ3M1am12cWY3In0.yYVJ1EpyEFcpNvFHCPRRCg'         

        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/euskooters/ckc38z9m00mr31il6c91y6auq',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
            }); 
            const interval = setInterval(async () => {                
                await this.createMarkers(map);          
                }, 5000);    
            
                this.setState({ intervalMap: interval });
                               
    }  
    
    
    getSkooters = async() => {
        let skootersRef = dbStore.collection('skooters').orderBy('id')
        skootersRef.get()
        .then(snapshot => {
          const skooters = []
          snapshot.forEach(skooter => {      
            skooters.push({ key: skooter.id, ...skooter.data() });
          });
          this.setState({skooters_new: skooters})
          return true    
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });  
        
      }

    async removeMarkers() {
        var elements = document.getElementsByClassName('marker');
        while (elements.length > 0) elements[0].remove();
      }
   
      
   async createMarkers(map) { 
    var skooters_get = this.getSkooters();
    try {
        if (skooters_get){
            this.removeMarkers();
            this.state.skooters_new.map (skooter => {   
       
                /* function desactPanic(id){ 
                     alert("pruebaaaaa" + id) 
                    }; */
          
                  var el = document.createElement('div');
                          el.className = 'marker';
                         try {
                          if(typeof skooter.status.code === 'undefined'){
                         } else {
          
                          if(skooter.battery_level >= 85){
                              el.style.backgroundImage = 'url("'+mapIcon+'")'  
                          } else if(skooter.battery_level <= 30 && skooter.battery_level !== ''){
                              el.style.backgroundImage = 'url("'+low+'")'  
                          } else if(30 < skooter.battery_level < 85){
                              el.style.backgroundImage = 'url("'+medium+'")' 
                          }
                          if(skooter.status.code === "inactive"){
                              el.style.backgroundImage = 'url("'+inactive+'")' 
                          }
                          if(skooter.status.code === "unlocked"){
                              el.style.backgroundImage = 'url("'+unlocked+'")' 
                          }
                         }
                      } catch {
                          console.log("Skooter sin datos: " + skooter.id)
                      }
                          el.style.width = '30px';
                          el.style.height = '30px'; 
                          if(skooter.key){ 
                              new mapboxgl.Marker(el)
                              .setLngLat([skooter.ultima_longitud, skooter.ultima_latitud])
                              .addTo(map); 
                            }
                          el.addEventListener('click', function () {
                             map.flyTo({
                      center: ([skooter.ultima_longitud, skooter.ultima_latitud]),
                      zoom: 15
                  }) 
                      
                  var popup = new mapboxgl.Popup({ closeOnClick: false })
                  .setLngLat([skooter.ultima_longitud, skooter.ultima_latitud])
                  .setHTML(
                      `<h4>${skooter.id}</h4>
                    <label for="file">Batería:&nbsp;${skooter.battery_level}%&nbsp;</label>
                    <progress id="file" value="${skooter.battery_level}" max="100">  </progress>  
                    <label for="file">Velocidad Instantánea:&nbsp;${skooter.speed}&nbsp;</label>
                    </br>
                    <label for="file">Odómetro:&nbsp;${skooter.odometer}&nbsp;</label>
                    </br>
                    <label for="file">Cobertura:&nbsp;${skooter.networkSignal}&nbsp;</label>
                    </br>
                    <label for="file">Rango restante:&nbsp;${skooter.remainingRange}&nbsp;</label>
                    </br>
                    <label for="file">Tiempo total:&nbsp;${skooter.totalRidingSecs}&nbsp;</label>   
                    </br>         
                                   
                    `
                  )        
                  .addTo(map);  
                
                ////botones del html
              //<button class="button_des" onClick="${commandSkooter(skooter.id,"PA0")}">Desactivar Pánico</button>
          
                //<button class="button_act" id="act-panic" >Activar Pánico</button>
                //<button class="button_des" onClick=commandSkooter(${skooter.id},"PA0")>Desactivar Pánico</button>    
                // document.getElementById('act-panic').addEventListener('click', commandSkooter(skooter.id, "PA0"))
                 //var sk = document.getElementById("act-panic")
                 //    sk.addEventListener("click", desactPanic(skooter.id))        
              });
                 return true})             
        }
    } catch {
        console.log("No GET")
    }
     

    }

    render() {         
        
        return (   
            <div className='map'>
                <div ref={ell => this.mapContainer = ell} className='mapContainer' />                  
                </div> 
        );
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto skooters en una matriz
    return {
        skooters: Object.values(state.skooters)     
    };
};

export default connect(mapStateToProps, { fetchAllSkooters })(SkootersMap);

/* `<h4>${skooter.id}</h4>
          <label for="file">Batería:&nbsp;${skooter.battery_level}%&nbsp;</label>
          <progress id="file" value="${skooter.battery_level}" max="100">  </progress> 
          <Link to={'/skooters/edit/${skooter.id}'} className="button primary"><Button>Editar</Button></Link>
          <button class="button_des" onClick=${commandSkooter(skooter.id,"PA0")}>Desactivar Pánico</button>         
          `  */