import React from 'react';
import { connect } from 'react-redux';
import { fetchUser, editUser } from './actions';
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Fade, Input } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput} from 'availity-reactstrap-validation';


class UserEdit extends React.Component{
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
    };
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        balance: '',  
        upv_free:false,      
        errors: null,
        fadein: false,
        message: ''
    } 
    this.handleSubmit = this.handleSubmit.bind(this);    
};

    componentDidMount() {       
        this.props.fetchUser(this.props.match.params.id);        
    };  

    handleSubmit(event, errors, values) {
      if(errors.length===0){        
        this.props.editUser(this.props.match.params.id, values)                  
        this.message('Usuario Editado Correctamente');                     
      } else this.message('Error campos no válidos');
    };

    message = (message) =>{
      this.setState({              
        fadein: true,
        message: message
      })
      setTimeout (()=>{
        this.setState({
          fadein: false,
          message: ''
        })
      },3000);      
    };

    render() {            
    if (!this.props.user){
      return (                 
        <div> 
        <AvForm onSubmit={this.handleSubmit} >
      <AvGroup>
        <Label htmlFor="userName" className="block">
          Nombre:
        </Label>
        <AvField
          type="text"
          name="userName"
          value = {this.state.name}
          placeholder="Nombre de usuario"
          id="userName"
          errorMessage="Nombre no válido"
          validate={{
              required: {value: true},
              pattern: {value: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9_-]*)+$'},
              minLength: {value: 2},
              maxLength: {value: 20}
            }}
        />
        </AvGroup>   
        <FormGroup>
        <Label htmlFor="userEmail" className="block">
          Correo:
        </Label>
        <AvField
          type="email"
          name="userEmail"
          value = {this.state.email}
          placeholder="Email del Usuario"
          id="userEmail"
          errorMessage="El correo del usuario es obligatorio"
          validate={{
              required: {value: true},
              minLength: {value: 4},
              maxLength: {value: 40}
            }}                  
        />   
        </FormGroup>
        <FormGroup>
        <Label htmlFor="userBalance" className="block">
          Balance:
        </Label>
        <AvField
          type="number"
          name="userBalance"
          value = {this.state.balance}
          placeholder="1"
          id="userBalance"        
        />
        </FormGroup>    
        <AvGroup check>
        <Label check>
          <AvInput type="checkbox" name="userUpv" value={this.state.upv_free} /> Suscripción Gratuita
        </Label>
      </AvGroup>                
      <Button color="danger" type="submit">Editar Usuario</Button>
      </AvForm>
      <Fade in={ this.state.fadein } tag="h3" className="mt-3 text-center text-success">
        { this.state.message }
        </Fade>       
        </div>   
      )
    }
    else {        
        const defaultValues = {
            userName: this.props.user.name,
            userEmail: this.props.user.email,
            userBalance: this.props.user.balance,
            userUpv: this.props.user.upv
        }        
        
    return (                 
      <div> 
      <AvForm onSubmit={this.handleSubmit} model={defaultValues}>
    <AvGroup>
      <Label htmlFor="userName" className="block">
        Nombre:
      </Label>
      <AvField
        type="text"
        name="userName"
        value = {this.state.name}
        placeholder="Nombre de usuario"
        id="userName"
        errorMessage="Nombre no válido"
        validate={{
            required: {value: true},
            pattern: {value: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9_-]*)+$'},
            minLength: {value: 2},
            maxLength: {value: 20}
          }}
      />
      </AvGroup>   
      <FormGroup>
      <Label htmlFor="userEmail" className="block">
        Correo:
      </Label>
      <AvField
        type="email"
        name="userEmail"
        value = {this.state.email}
        placeholder="Email del Usuario"
        id="userEmail"
        errorMessage="El correo del usuario es obligatorio"
        validate={{
            required: {value: true},
            minLength: {value: 4},
            maxLength: {value: 40}
          }}                  
      />   
      </FormGroup>
      <FormGroup>
      <Label htmlFor="userBalance" className="block">
        Balance:
      </Label>
      <AvField
        type="number"
        name="userBalance"
        value = {this.state.balance}
        placeholder="1"
        id="userBalance"        
      />
      </FormGroup>  
      <AvGroup check>
        <Label check>
          <AvInput type="checkbox" name="userUpv" /> Suscripción Gratuita
        </Label>
      </AvGroup>     
    <Button color="danger" type="submit">Editar Usuario</Button>
    </AvForm>
    <Fade in={ this.state.fadein } tag="h3" className="mt-3 text-center text-success">
      { this.state.message }
      </Fade>       
      </div>   
    );}
    }
};

const mapStateToProps = (state, ownProps) => {
    return { user: state.users[ownProps.match.params.id]};
};

export default connect(mapStateToProps, { fetchUser, editUser })(UserEdit);