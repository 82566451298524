import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Row, Col  } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { fetchPartners, fetchPartnersPage, fetchPartnersSearch, countPartners } from './actions';
import Pagination from "react-js-pagination";
import './PartnerList.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

class PartnerEditDelete extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
          activePage: 1,
          name:'',
          partnerCount: countPartners()
        };

        this.handleSubmit = this.handleSubmit.bind(this); 
        this.handleChange = this.handleChange.bind(this);       
      }

      handleChange(event) {
        const {value} = event.currentTarget;
        this.setState({ name: value })        
    } 
 

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState({activePage: pageNumber});
        this.props.fetchPartnersPage(pageNumber);        
      }

    handleSubmit(event, errors, values) {
        //console.log('entra al submit')
        if(errors.length===0){   
            console.log('entra al metodo de buscar')     
          this.props.fetchPartnersSearch(values.partnerId)   
          this.setState({activePage: 1});               
                              
        } else console.log('no entra al metodo')
      };

   async componentDidMount(){
        this.props.fetchPartners();
        this.setState({partnerCount: await countPartners()});
    }

    
    renderList(){
          return this.props.partners.map (partner => {
            if(partner.key){ 
            return (
                    <tr key={partner.key}>
                    <td>{partner.name}</td>                                                               
                    <td><Link to={`/partners/edit/${partner.id}`} className="button primary"><Button><FontAwesomeIcon className="fas fa-fw" icon={faEdit} width="25" height="25"/></Button></Link></td>
                    <td><Link to={`/partners/delete/${partner.id}`} className="button negative"><Button color="danger"><FontAwesomeIcon className="fas fa-fw" icon={faTimes} width="25" height="25"/></Button></Link></td>
                    </tr>          
                 )}  else {
                    return true;
                }         
            }
        )
    }

  
    render() {
        if(this.state.partnerCount !== 0) { 
            return (
                <div>
                    <div>
                    <Row>
                        <Col sm="12" md={{ size: 7 }}>
                            <h2 className="text-center">Socios</h2>
                        </Col>                    
                    <Col sm="12" md={{ size: 5 }}>
                        <div className="buscar">
                    <AvForm onSubmit={this.handleSubmit}>
                        <AvGroup>
                        <AvField
                            type="text"
                            name="userEmail"
                            placeholder="Email del Usuario"
                            id="userEmail"
                            onChange={this.handleChange}
                            value = {this.state.name}
                            errorMessage="Debe introducir un parámetro de búsqueda"
                            validate={{
                                required: {value: true}                            
                            }}                  
                        />   
                        </AvGroup>
                        <Button color="danger" type="submit">Buscar Partner</Button>
                    </AvForm> 
                    </div>
                    </Col>  
                    </Row> 
                    </div> 
                    <Table hover className="text-center">
                        <thead>
                            <tr>
                                <th>Nombre</th>                            
                                <th>Editar</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>                     
                    </Table>  
                    <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.partnerCount}
                            pageRangeDisplayed={8}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}         
                        />                             
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // convierte el objeto partners en una matriz
    return {
        partners: Object.values(state.partners)     
    };
};

export default connect(mapStateToProps, { fetchPartners, fetchPartnersPage, fetchPartnersSearch })(PartnerEditDelete);

