import React from 'react';
import { connect } from 'react-redux';
import { fetchSkooter } from './actions';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
//import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Redirect } from 'react-router-dom';
import QRCode from 'qrcode.react';

class SkooterEdit extends React.Component{
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
    };
    constructor(props) {
      super(props);
      this.state = {
        errors: null,
        fadein: false,
        message: ''
    }        
}

    componentDidMount() {
        this.props.fetchSkooter(this.props.match.params.id);
    }  
      
       downloadQR = () => {
        const canvas = document.getElementById(this.props.skooter.id);
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = "black";
        ctx.textBaseline = 'middle';
        ctx.font = "4px 'Poppins'";
        ctx.fillText(this.props.skooter.id, 8, 27.5);
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");                 
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = this.props.skooter.id+".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    message = (message) =>{
      this.setState({      
        fadein: true,
        message: message
      })
      setTimeout (()=>{
        this.setState({
          fadein: false,
          message: ''
        })
      },3000);
    }

    render() {          
    if (!this.props.skooter){                 
        return (<Redirect to={{
          pathname: '/Upd_Delete_skooters'
        }}/>)    
    }
    
    return (
        <div>
            <h3>SkooterQR</h3>            
            <QRCode
                id={this.props.skooter.id}
                value={this.props.skooter.id}
                size={115}
                level={"M"}
                includeMargin={true}
                imageSettings={{
                  src: "../../../euskooter-green.ico", 
                  x: null,
                  y: null,
                  height: 15,
                  width: 15,
                  excavate: true,
                }}  
                                                        
            />  
            <h4>{this.props.skooter.id}</h4>
            <Button color="danger" onClick={this.downloadQR}> Descargar QR </Button>
        </div>              
    );}
    }

const mapStateToProps = (state, ownProps) => {
    return { skooter: state.skooters[ownProps.match.params.id]};
};

export default connect(mapStateToProps, { fetchSkooter })(SkooterEdit);