import React, { useState } from 'react';
import '../tasks/tasks-page.css';
import SideBar from '../../components/sidebar/SideBar';
import Content from '../../components/content/Content';
import { BrowserRouter as Router } from 'react-router-dom';
import history from '../../../src/history';

export default () => {

  const [isOpen, setOpen] = useState(true)
  const toggle = () => setOpen(!isOpen)

  return (
    <Router history={history}>
      <div className="App wrapper">
        <SideBar toggle={toggle} isOpen={isOpen}/>
        <Content toggle={toggle} isOpen={isOpen}/>
      </div>      
    </Router>
  );
}

