import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { connect } from 'react-redux';
import { fetchTravelsLoading } from './../travels/actions';
import Spinner from 'react-bootstrap/Spinner'
//import { userEmail } from './../users/actions';
import PropTypes from 'prop-types';
import { Table, Col, Row } from 'reactstrap';

class SkooterTravels extends React.Component{
  static propTypes = {
  //  onSubmit: PropTypes.func.isRequired
    };
    constructor(props) {
      super(props);
      this.state = {
        id: '',        
        errors: null,
    }    
}

    componentDidMount() { 
        console.log(this.props.match.params.id)
        this.props.fetchTravelsLoading(this.props.match.params.id);
    }
    
   /* userMail(idUser){
        return userEmail(idUser)     
    }*/

    renderList(){
        const { travels } = this.props;        
        if(travels){
            //console.log('tiene viajes')         
       return travels.map (travel => {              
            if(travel.id){  
                //console.log('ID' + travel.id)
                var endDate, endTime, startDate, startTime, totalTimeMin, totalTimeSec, distance, userId, balance_Before, balance_After, totalCost = null
                if(typeof travel.end === 'undefined')
                { endTime = "No registrado"
                  endDate = "No registrado"
                }else{
                   endDate = travel.end.toDate().toLocaleDateString()
                   endTime = travel.end.toDate().toLocaleTimeString()
                }
                if(typeof travel.start === 'undefined')
                { startTime = "No registrado"
                  startDate = "No registrado"
                }else{
                    startDate = travel.start.toDate().toLocaleDateString()
                    startTime = travel.start.toDate().toLocaleTimeString()
                }
                if(typeof travel.total_time === 'undefined')                
                {totalTimeMin = "No registrado"
                 totalTimeSec = "No registrado"
                }else{
                    totalTimeMin = parseFloat(travel.total_time/60).toFixed(0)
                    totalTimeSec = parseFloat(travel.total_time%60).toFixed(0)
                }
                if(typeof travel.distance === 'undefined')                
                {distance = "No registrado"}else{
                    distance = parseFloat(travel.distance).toFixed(2)
                }
                if(typeof travel.user_id === 'undefined')
                {userId = "No registrado"}else{
                    userId = travel.user_id
                }
                if(typeof travel.balanceAfter === 'undefined')
                {balance_After= "No registrado"}else{
                    balance_After = parseFloat(travel.balanceAfter).toFixed(2)
                }
                if(typeof travel.balanceBefore === 'undefined')
                {balance_Before = "No registrado" }else{
                    balance_Before = parseFloat(travel.balanceBefore).toFixed(2)
                }
                if(typeof travel.total_cost  === 'undefined')
                {totalCost = "No registrado"}else{
                    totalCost = parseFloat(travel.total_cost).toFixed(2)
                }      
          return (
                  <tr key={travel.id}>
                  <td>{userId}</td>
                  <td>{startDate}<br></br> {startTime}</td>                                                            
                  <td>{endDate} <br></br> {endTime}</td>
                  <td>{totalTimeMin}min, {totalTimeSec}seg</td> 
                  <td>{distance}m</td>
                  <td>{balance_Before}</td>
                  <td>{balance_After}</td>
                  <td>{totalCost}</td>
                  </tr> 
              )}   else {
                return true;
            }   
          }
      )  
    }  
      
  };
    
    render() {            
        const { loading, error } = this.props;
        if(loading){
           return (
            <div className="spinner">
                <Spinner animation="border" role="status">
                <span className="sr-only">Cargando datos...</span>
                </Spinner>
            </div>
           )
        }     

        if(error){
            return (
             <div className="spinner">
                 <h3>Ha ocurrido un error intenténtelo de nuevo en unos segundos</h3>
             </div>
            )
         }   
        return (
            
              <div>
                  <div>
                  <Row>
                      <Col sm="12" md={{ size: 12 }}>
        <h2 className="text-center">Viajes del Patinete {this.props.match.params.id}</h2>
                      </Col> 
                  </Row> 
                  </div>             
                  <Table hover className="text-center">
                      <thead>
                          <tr>
                              <th>Usuario</th>
                              <th> Inicio de viaje</th>
                              <th>Fin de viaje</th>
                              <th> Tiempo de Viaje</th>
                              <th>Distancia</th>                          
                              <th>Balance Inicial</th>
                              <th>Balance Final</th>
                              <th>Costo Total</th>
                          </tr>
                      </thead>
                      <tbody>{this.renderList()}</tbody>                    
                  </Table>                  
              </div>
          );
      }
  }
  
  
  const mapStateToProps = state => ({
    travels: state.travels.items,
    loading: state.travels.loading,
    error: state.travels.error
  });
  

/*  const mapStateToProps = (state) => {
    try{
   return { travels: Object.values(state.travels)} 
    } catch{
        return {
            skooters: Object.values(state.skooters)            
        };
    }
};*/


export default connect(mapStateToProps, { fetchTravelsLoading })(SkooterTravels);

