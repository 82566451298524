import firebase from 'firebase/app';
//import * as firebase from 'firebase';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions'

import { firebaseConfig } from './config';


export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const firebaseDb = firebase.database();
export const funct = firebase.functions();


const dbs = () => {  
let db = firebase.firestore();
db.settings({});
return db;
}

export const dbStore = dbs();

const dbstorage = () => {  
  const st = firebase.storage();
  return st;
  }
  
export const dbStorage = dbstorage();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
    firebaseAuth.signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = dbStore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { email } = user;
    try {
      await userRef.set({
        email,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};


const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await dbStore.doc(`users/${uid}`).get();
    if(userDocument.data().roles.admin){
    return {
      uid,
      ...userDocument.data()
    };}else {
      alert("No tiene permisos para acceder a la aplicación")
    }
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
