//import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { skootersReducer } from './components/skooters/reducer';
import { usersReducer } from './components/users/reducer';
import { travelReducer } from './components/travels/reducer';
import { placesReducer } from './components/places/reducer';
import { partnersReducer } from './components/partners/reducer';
//import { history } from './history'
//import { notificationReducer } from './notification';
//import { tasksReducer } from './tasks';


const createRootReducer = (history) => combineReducers({
  auth: authReducer,
  skooters: skootersReducer,
  users: usersReducer,
  places: placesReducer,
  travels: travelReducer,
  partners: partnersReducer,
  router: connectRouter(history)  
});
export default createRootReducer

/*import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ... // rest of your reducers
})
export default createRootReducer*/